type SpacePropNameType = 'margin' | 'padding'
type SpacePropType = 'Top' | 'Bottom' | 'Left' | 'Right'

export type SpacePropsType<T extends string> = Record<
  `${T}${SpacePropType}`,
  number | string
>

export type SpaceObjectType = {
  top: number | string
  bottom: number | string
  left: number | string
  right: number | string
}

export type SpaceType = string | number | SpaceObjectType

const spaces = (
  value: SpaceType | undefined,
  propName: SpacePropNameType
): SpacePropsType<string> => {
  const isSpObj = typeof value === 'object'
  return {
    [`${propName}Top`]: (isSpObj ? value.top : value) ?? 0,
    [`${propName}Bottom`]: (isSpObj ? value.bottom : value) ?? 0,
    [`${propName}Left`]: (isSpObj ? value.left : value) ?? 0,
    [`${propName}Right`]: (isSpObj ? value.right : value) ?? 0,
  }
}

export default spaces
