import { AnalyticReport } from '@tvi/types/analytic'
import TviApiService from '../../TviApiService'

export type GetAnalyticArgs = {
  videoId: number
}
export type GetAnalyticResponse = AnalyticReport

export interface AnalyticEndpoints {
  getAnalytic(args: GetAnalyticArgs): Promise<GetAnalyticResponse>
}

export default class AnalyticService
  extends TviApiService
  implements AnalyticEndpoints
{
  async getAnalytic({
    videoId,
  }: GetAnalyticArgs): Promise<GetAnalyticResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/videos/${videoId}/analytics`),
    }).then(this.takeOk)
  }
}
