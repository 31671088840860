import { LayoutRect } from '@dnd-kit/core'

export type TransformData = {
  x: number
  y: number
  scaleX: number
  scaleY: number
}

export type SortingStrategyArgs = {
  activeNodeRect: LayoutRect | null | undefined
  activeIndex: number
  overIndex: number
  index: number
}

const horizontalSortingStrategy = ({
  activeNodeRect,
  activeIndex,
  overIndex,
  index,
}: SortingStrategyArgs): TransformData | null => {
  if (!activeNodeRect) return null
  if (index > activeIndex && index <= overIndex) {
    return {
      x: -activeNodeRect.width,
      y: 0,
      scaleX: 1,
      scaleY: 1,
    }
  }

  if (index < activeIndex && index >= overIndex) {
    return {
      x: activeNodeRect.width,
      y: 0,
      scaleX: 1,
      scaleY: 1,
    }
  }

  return {
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
  }
}

export default horizontalSortingStrategy
