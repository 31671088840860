import { Box, ClickAwayListener, Divider } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import { Theme } from '@mui/material/styles/createTheme'
import clsx from 'clsx'
import React, { FC, Fragment, useCallback, useState } from 'react'
import WrapInto from '../../utils/WrapInto'

const getHideBorderStyle = (theme: Theme) => ({
  borderStyle: 'solid',
  borderWidth: (
    theme.components?.MuiOutlinedInput?.styleOverrides?.notchedOutline as any
  )?.borderWidth,
  borderColor: (
    theme.components?.MuiOutlinedInput?.styleOverrides?.notchedOutline as any
  )?.borderColor,

  '&:hover:not(.Mui-focused)': {
    borderColor: theme.palette?.primary?.contrastText,
    borderStyle: 'solid',
  },

  '&.Mui-focused': {
    borderColor: theme.palette?.primary?.main,
    borderStyle: 'solid',
  },

  [`.MuiOutlinedInput-notchedOutline,
   :hover .MuiOutlinedInput-notchedOutline,
    .Mui-focused .MuiOutlinedInput-notchedOutline`]: {
    border: '0px solid transparent !important',
  },
})

export type GroupControlsProps = {
  hideCommonBorder?: boolean
  divider?: boolean
} & BoxProps

const GroupControls: FC<GroupControlsProps> = ({
  children,
  hideCommonBorder = false,
  divider = false,
  ...rest
}) => {
  const hideBorder = hideCommonBorder || divider
  const childrenArr = React.Children.toArray(children).filter(
    (item) => React.isValidElement(item) && item !== null && item !== undefined
  )
  const [focused, setFocused] = useState<boolean>(false)
  const handleFocusAway = useCallback(() => {
    setFocused(false)
  }, [])
  const handleFocus = useCallback(
    (e) => {
      if (hideBorder) setFocused(true)
      rest.onClick?.(e)
    },
    [rest.onClick]
  )

  return (
    <WrapInto isWrap={hideBorder}>
      <ClickAwayListener onClickAway={handleFocusAway}>
        <Box
          {...rest}
          onClick={handleFocus}
          role="group"
          className={clsx({ 'Mui-focused': focused })}
          sx={(theme) => ({
            // @ts-ignore
            ...theme.components?.MuiOutlinedInput?.styleOverrides?.root,
            ...(hideBorder ? getHideBorderStyle(theme) : undefined),
            display: 'inline-flex',
            alignItems: 'center',
            boxSizing: 'border-box',

            [`& > :not(:first-child):not(:last-child) .MuiOutlinedInput-root,
        & > :not(:first-child):not(:last-child) .MuiButton-outlined,
        & > :not(:first-child):not(:last-child) .MuiButton-contained,
        & > .MuiOutlinedInput-root:not(:first-child):not(:last-child),
        & > .MuiButton-outlined:not(:first-child):not(:last-child),
        & > .MuiButton-contained:not(:first-child):not(:last-child)`]: {
              borderRadius: 0,
              borderLeft: '0px solid transparent',
              borderRight: '0px solid transparent',
            },

            [`& > :first-child .MuiOutlinedInput-root,
        & > :first-child .MuiButton-outlined,
        & > :first-child .MuiButton-contained,
        & > .MuiOutlinedInput-root:first-child,
        & > .MuiButton-outlined:first-child,
        & > .MuiButton-contained:first-child`]: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: '0px solid transparent',
            },

            [`& > :last-child .MuiOutlinedInput-root,
        & > :last-child .MuiButton-outlined,
        & > :last-child .MuiButton-contained,
        & > .MuiOutlinedInput-root:last-child,
        & > .MuiButton-outlined:last-child,
        & > .MuiButton-contained:last-child`]: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeft: '0px solid transparent',
            },
            ...rest.sx,
          })}
        >
          {!divider
            ? children
            : childrenArr.map((child, index, arr) => {
                const last = arr.length - 1
                if (arr.length === 1) return child
                if (index === last) {
                  return child
                }
                return (
                  <Fragment key={index}>
                    {child}
                    <Divider
                      sx={{ height: 38, m: -0.5 }}
                      orientation="vertical"
                    />
                  </Fragment>
                )
              })}
        </Box>
      </ClickAwayListener>
    </WrapInto>
  )
}

export default GroupControls
