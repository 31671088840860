import withTheme from '../../utils/withTheme'
/* views */
// organize-imports-ignore
import AudioCardViewUnstyled, { AudioCardViewProps } from './AudioCardView'
import DropzoneViewUnstyled, { DropzoneViewProps } from './DropzoneView'
/* views END */

export type { AudioCardViewProps } from './AudioCardView'
export const AudioCardView = withTheme<AudioCardViewProps>(
  AudioCardViewUnstyled,
  'AudioCardView'
)

export type { DropzoneViewProps } from './DropzoneView'
export const DropzoneView = withTheme<DropzoneViewProps>(
  DropzoneViewUnstyled,
  'AudioCardView'
)
