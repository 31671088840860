export const getHTMLFontSize = (): string => {
  const DEFAULT_FONT_SIZE = '16px'
  if (!window) return DEFAULT_FONT_SIZE
  return (
    window
      .getComputedStyle(document.getElementsByTagName('html')[0], null)
      .getPropertyValue('font-size') || DEFAULT_FONT_SIZE
  )
}
export const remToPx = (rem: string | number): string => {
  const px = parseFloat(rem.toString()) * parseFloat(getHTMLFontSize())
  return px + 'px'
}

export const pxToRem = (
  px: string | number,
  htmlFontSize: string | number = 16
): string => {
  const rem = parseFloat(px.toString()) / parseFloat(htmlFontSize.toString())
  return rem + 'rem'
}
