import withTheme from '../../utils/withTheme'
import FilmstripUnstyled, { FilmstripProps } from './Filmstrip'
import FilmstripItemUnstyled, { FilmstripItemProps } from './FilmstripItem'
import FilmstripScaleUnstyled, { FilmstripScaleProps } from './FilmstripScale'

export type { FilmstripProps } from './Filmstrip'
export * from './FilmstripGroup'
export { default as FilmstripGroup } from './FilmstripGroup'
export type { FilmstripItemProps } from './FilmstripItem'
export * from './FilmstripScale'
export type { FilmstripScaleProps } from './FilmstripScale'
export const FilmstripItem = withTheme<FilmstripItemProps>(
  FilmstripItemUnstyled,
  'FilmstripItem'
)

export const FilmstripScale = withTheme<FilmstripScaleProps>(
  FilmstripScaleUnstyled,
  'FilmstripScale'
)

const Filmstrip = withTheme<FilmstripProps>(FilmstripUnstyled, 'Filmstrip')
export default Filmstrip
