import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { FC, Fragment, ReactNode, useRef, useState } from 'react'
import Button, { ButtonProps } from './index'

export type OptionItem = {
  key: string | number
  content: ReactNode
} & Partial<Omit<MenuItemProps, 'content' | 'key'>>

export type ButtonMenuProps = {
  text?: ReactNode
  options?: (OptionItem | string)[]
  onChange?(option: OptionItem | string, index: number): void
} & Partial<ButtonProps>

const ButtonMenu: FC<ButtonMenuProps> = ({
  text,
  children,
  options,
  onChange,
  ...buttonProps
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <Fragment>
      <Button {...buttonProps} ref={anchorRef} onClick={handleToggle}>
        {text}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        style={{ zIndex: 1 }}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {!options
                    ? children
                    : options.map((option, index) => (
                        <MenuItem
                          {...option}
                          key={typeof option === 'string' ? index : option?.key}
                          onClick={
                            onChange
                              ? () => onChange?.(option, index)
                              : undefined
                          }
                        >
                          {typeof option === 'string'
                            ? option
                            : option?.content}
                        </MenuItem>
                      ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

export default ButtonMenu
