import {
  Box,
  Modal,
  ModalProps,
  Paper,
  PaperProps,
  Typography,
} from '@mui/material'
import { FC } from 'react'
import { Button } from '../../index'

export type ConfirmProps = {
  isOpen: boolean
  size?: 'small' | 'standard'
  header?: string
  description?: string
  cancelLabel?: string
  confirmLabel?: string
  onCancel(): void
  onConfirm(): void
  modalProps?: ModalProps
} & PaperProps

const Confirm: FC<ConfirmProps> = ({
  isOpen,
  size = 'standard',
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  onCancel,
  onConfirm,
  header = '',
  description = '',
  children,
  modalProps = {},
  ...rest
}) => {
  return (
    <Modal {...modalProps} open={isOpen}>
      <Paper
        {...rest}
        sx={{
          width: 500,
          paddingX: 80,
          paddingY: 50,
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '2px',
          background: '#1E232F',
          ...rest.sx,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {children}
          {header && (
            <Typography
              variant="h1"
              sx={{
                marginBottom: 16,
                fontSize: 36,
                textAlign: 'center',
                whiteSpace: 'pre-line',
              }}
            >
              {header}
            </Typography>
          )}
          {description && (
            <Typography
              variant="body1"
              sx={{
                marginBottom: 20,
                textAlign: 'center',
                whiteSpace: 'pre-line',
              }}
            >
              {description}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
          }}
        >
          <Button
            variant="contained"
            color="neutral"
            onClick={onCancel}
            sx={{
              marginRight: 15,
              height: '40px',
              lineHeight: 'unset',
            }}
          >
            {cancelLabel}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={onConfirm}
            sx={{
              height: '40px',
              lineHeight: 'unset',
            }}
          >
            {confirmLabel}
          </Button>
        </Box>
      </Paper>
    </Modal>
  )
}

export default Confirm
