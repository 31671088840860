import withTheme from '../../utils/withTheme'
import AudioTimelineUnstyled, { AudioTimelineProps } from './AudioTimeline'

export type { AudioTimelineProps } from './AudioTimeline'
export { default as AudioTimelineCtx } from './AudioTimelineCtx'
export type { AudioTimelineCtxProps } from './AudioTimelineCtx'

const AudioTimeline = withTheme<AudioTimelineProps>(
  AudioTimelineUnstyled,
  'AudioTimeline'
)
export default AudioTimeline
