import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

const TVIListIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M 6.668 19 L 4 19 L 4 16.2 L 6.668 16.2 L 6.668 19 Z M 6.668 10.6 L 4 10.6 L 4 13.4 L 6.668 13.4 L 6.668 10.6 Z M 6.668 5 L 4 5 L 4 7.8 L 6.668 7.8 L 6.668 5 Z M 8.668 5 L 8.668 7.8 L 20 7.8 L 20 5 L 8.668 5 Z M 8.668 13.4 L 20 13.4 L 20 10.6 L 8.668 10.6 L 8.668 13.4 Z M 8.668 19 L 20 19 L 20 16.2 L 8.668 16.2 L 8.668 19 Z" />
    </SvgIcon>
  )
}

export default TVIListIcon
