import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

const TVICheckIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M 10 18.334 L 4 12.514 L 6.477 10.036 L 10 13.378 L 17.523 5.666 L 20 8.143 L 10 18.334 Z" />
    </SvgIcon>
  )
}

export default TVICheckIcon
