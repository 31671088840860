import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/system'
import { FC, useCallback, useRef, useState } from 'react'
import { inLimit } from '../../utils/helpers'
import { TVICircleLeftIcon, TVICircleRightIcon } from '../icons'

export type PaginationEvent = {
  prev: number
  next: number
  isEdge: boolean
}

export type PaginationProps = {
  num: number
  onChange(event: PaginationEvent): void
  loop?: boolean
  defaultValue?: number
  startIndex?: number
  size?: number
  sx?: SxProps
}

const Pagination: FC<PaginationProps> = ({
  loop = false,
  num,
  defaultValue,
  onChange,
  startIndex = 1,
  size = 30,
  sx,
}) => {
  const initValue = inLimit(startIndex, num, defaultValue ?? startIndex)
  const countRef = useRef<number>(initValue)
  const [, shouldDisable] = useState<boolean>(
    initValue === startIndex || initValue === num
  )

  const next = useCallback(() => {
    const prev = countRef.current
    const nextValue = inLimit(startIndex, num, countRef.current + 1)
    const next = loop && nextValue === prev ? startIndex : nextValue
    const isEdge = loop ? false : num === next
    countRef.current = next
    shouldDisable(isEdge)
    onChange({
      prev,
      next,
      isEdge,
    })
  }, [num, loop, startIndex, onChange, countRef])

  const prev = useCallback(() => {
    const prev = countRef.current
    const nextValue = inLimit(startIndex, num, countRef.current - 1)
    const next = loop && nextValue === prev ? num : nextValue
    const isEdge = loop ? false : next === startIndex
    countRef.current = next
    shouldDisable(isEdge)
    onChange({
      prev,
      next,
      isEdge,
    })
  }, [num, loop, startIndex, onChange])

  return (
    <Box>
      <IconButton
        aria-label="prev page"
        color="primary"
        onClick={prev}
        disabled={loop ? false : countRef.current === startIndex}
      >
        <TVICircleLeftIcon
          viewBox="4 4 16 16"
          sx={{ fontSize: size, margin: 4, ...sx }}
        />
      </IconButton>
      <IconButton
        aria-label="next page"
        color="primary"
        onClick={next}
        disabled={loop ? false : countRef.current === num}
      >
        <TVICircleRightIcon
          viewBox="4 4 16 16"
          sx={{ fontSize: size, margin: 4, ...sx }}
        />
      </IconButton>
    </Box>
  )
}

export default Pagination
