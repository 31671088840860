import {
  Playlist,
  PlaylistItem,
  PlaylistItemDetails,
} from '@tvi/types/playlist'
import { ServiceProfileServices } from '@tvi/types/serviceProfile'
import { SuccessResponse } from '../..'
import TviApiService from '../../TviApiService'

export type GetPlaylistsArgs = {
  brandId: number
  service: ServiceProfileServices
  serviceProfileId?: number
}

export type GetPlaylistItemsArgs = {
  brandId: number
  service: ServiceProfileServices
  playlistId: string
}

export type GetPlaylistItemDetailArgs = {
  brandId: number
  service: ServiceProfileServices
  resourceId: string
}

export type InitiateDownloadArgs = {
  brandId: number
  resourceId: string
  serviceProfileId: number
}

export type GetPlaylistsResponse = {
  playlists: Playlist[]
}

export type GetPlaylistItemsResponse = {
  playlist_items: PlaylistItem[]
}

export type GetPlaylistItemDetailedResponse = PlaylistItemDetails

export interface PlaylistEndpoints {
  getPlaylists(args: GetPlaylistsArgs): Promise<GetPlaylistsResponse>
  getPlaylistItems(
    args: GetPlaylistItemsArgs
  ): Promise<GetPlaylistItemsResponse>
  getPlaylistItemDetails(
    args: GetPlaylistItemDetailArgs
  ): Promise<GetPlaylistItemDetailedResponse>
  initiateDownload(args: InitiateDownloadArgs): Promise<SuccessResponse>
}

export default class PlaylistsService
  extends TviApiService
  implements PlaylistEndpoints
{
  async getPlaylists({
    brandId,
    service,
    serviceProfileId,
  }: GetPlaylistsArgs): Promise<GetPlaylistsResponse> {
    return this.request({
      method: 'GET',
      url: this.url(
        `/brands/${brandId}/${service}/playlists?service_profile_id=${serviceProfileId}`
      ),
    }).then(this.takeOk)
  }

  async getPlaylistItems({
    brandId,
    service,
    playlistId,
  }: GetPlaylistItemsArgs): Promise<GetPlaylistItemsResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/brands/${brandId}/${service}/playlists/${playlistId}`),
    }).then(this.takeOk)
  }

  async getPlaylistItemDetails({
    brandId,
    service,
    resourceId,
  }: GetPlaylistItemDetailArgs): Promise<GetPlaylistItemDetailedResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/brands/${brandId}/${service}/videos/${resourceId}`),
    }).then(this.takeOk)
  }

  async initiateDownload({
    brandId,
    resourceId,
    serviceProfileId,
  }: InitiateDownloadArgs): Promise<SuccessResponse> {
    return this.request({
      method: 'POST',
      url: this.url(
        `/brands/${brandId}/projects/download/youtube/${resourceId}?service_profile_id=${serviceProfileId}`
      ),
    }).then(this.takeOk)
  }
}
