import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Tab, { TabProps } from '@mui/material/Tab'
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs'
import { SxProps } from '@mui/system'
import { FC, SyntheticEvent, useState } from 'react'

const horizontalTabStyles: SxProps = {
  fontWeight: 500,
  '&.Mui-selected': {
    color: 'primary.contrastText',
  },
}
const verticalTabStyles: SxProps = {
  minHeight: '54px',
  alignItems: 'center',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  borderBottom: '1px solid #343944',
  '&:first-of-type': {
    borderTop: '1px solid #343944',
  },
}

export type TabsProps = Omit<
  MuiTabsProps,
  'children' | 'onChange' | 'value'
> & {
  tabLabels: string[]
  onChange(activeTabIndex: number): void
  activeTabIndex?: number
  TabProps?: Omit<TabProps, 'label'>
}

const Tabs: FC<TabsProps> = ({
  tabLabels,
  activeTabIndex = 0,
  onChange,
  TabProps,
  ...rest
}) => {
  const isVertical: boolean = rest.orientation === 'vertical'
  const sxTab = isVertical ? verticalTabStyles : horizontalTabStyles
  const [index, setIndex] = useState<number>(activeTabIndex)

  const handleChange = (event: SyntheticEvent, activeTabIndex: number) => {
    setIndex(activeTabIndex)
    onChange(activeTabIndex)
  }

  return (
    <MuiTabs {...rest} value={index} onChange={handleChange}>
      {tabLabels.map((label) => (
        <Tab
          icon={
            isVertical ? (
              <ArrowForwardIosIcon sx={{ fontSize: 19 }} />
            ) : undefined
          }
          {...TabProps}
          key={label}
          label={label}
          sx={TabProps?.sx ? { ...sxTab, ...TabProps.sx } : sxTab}
        />
      ))}
    </MuiTabs>
  )
}

export default Tabs
