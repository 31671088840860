import React, { FC } from 'react'

export type WrapIntoProps = {
  isWrap: boolean
}

const WrapInto: FC<WrapIntoProps> = ({ isWrap, children }): any => {
  React.Children.only(children)
  return isWrap
    ? children
    : React.Children.map(children, (child) => {
        return React.isValidElement(child) ? child?.props?.children : child
      })
}

export default WrapInto
