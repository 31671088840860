import styled from '@emotion/styled'

export const AudioTrimContainer = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
`

export const TRIM_EDGE_WIDTH = 6
export const TrimEdge = styled.div(
  (props: { right?: boolean; left?: boolean }) => ({
    width: 4,
    height: '100%',
    position: 'absolute',
    top: 0,
    [props.right ? 'right' : 'left']: 0,
    zIndex: 1,
    transitionDuration: '0.2s',
    transitionProperty: 'width',
    borderRadius: '4px',
    cursor: 'ew-resize',
    '&:hover, .trimming &': {
      width: TRIM_EDGE_WIDTH,
      background: 'rgba(255, 255, 255, 0.6)',
    },
  })
)
