import { closestCenter, DndContext, DndContextProps } from '@dnd-kit/core'
import {
  DragCancelEvent,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
} from '@dnd-kit/core/dist/types'
import { createContext, FC, useCallback, useState } from 'react'

export const audioTimelineDragCtx = createContext<
  (args: DndContextProps) => void
>(() => {})

const DndInversionPropsProvider = audioTimelineDragCtx.Provider

export type AudioTimelineCtxProps = {} & Pick<
  DndContextProps,
  | 'collisionDetection'
  | 'onDragStart'
  | 'onDragMove'
  | 'onDragOver'
  | 'onDragEnd'
  | 'onDragCancel'
>

const AudioTimelineCtx: FC<AudioTimelineCtxProps> = ({
  onDragStart,
  onDragMove,
  onDragOver,
  onDragEnd,
  onDragCancel,
  collisionDetection = closestCenter,
  children,
}) => {
  const [dndCtxProps, setDndCtxProps] = useState<DndContextProps>({})
  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      dndCtxProps?.onDragStart?.(event)
      onDragStart?.(event)
    },
    [onDragStart, dndCtxProps?.onDragStart]
  )
  const handleDragMove = useCallback(
    (event: DragMoveEvent) => {
      dndCtxProps?.onDragMove?.(event)
      onDragMove?.(event)
    },
    [onDragMove, dndCtxProps?.onDragMove]
  )
  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      dndCtxProps?.onDragOver?.(event)
      onDragOver?.(event)
    },
    [onDragOver, dndCtxProps?.onDragOver]
  )
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      dndCtxProps?.onDragEnd?.(event)
      onDragEnd?.(event)
    },
    [onDragEnd, dndCtxProps?.onDragEnd]
  )
  const handleDragCancel = useCallback(
    (event: DragCancelEvent) => {
      dndCtxProps?.onDragCancel?.(event)
      onDragCancel?.(event)
    },
    [onDragCancel, dndCtxProps?.onDragCancel]
  )
  return (
    <DndInversionPropsProvider value={setDndCtxProps}>
      <DndContext
        {...dndCtxProps}
        collisionDetection={collisionDetection}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        {children}
      </DndContext>
    </DndInversionPropsProvider>
  )
}

export default AudioTimelineCtx
