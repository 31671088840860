import { FC } from 'react'
import AudioClip from '../audio-clip'

type ClipProps = {
  width: number
  height: number
  isActive: boolean
  data?: any
}

const Clip: FC<ClipProps> = ({ width, height, isActive, data, ...rest }) => {
  return (
    <AudioClip
      {...rest}
      upperPoints={data.upperPoints}
      lowerPoints={data.lowerPoints}
      width={Math.max(width, 0)}
      height={Math.max(height, 0)}
      waveColor={isActive ? undefined : '#505257'}
      style={{
        background: isActive ? '#2b8ea0' : '#6f7278',
        border: `1px solid #4DB0C2`,
        borderRadius: '4px',
        boxSizing: 'border-box',
      }}
    />
  )
}

export default Clip
