import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

const TVICircleLeftIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <circle cx="-12" cy="-12" r="8" transform="matrix(-1, 0, 0, -1, 0, 0)" />
      <path
        d="M 10.628 15.2 L 10.171 15.2 L 10.171 14.743 L 12.914 12 L 10.171 9.257 L 10.171 8.8 L 10.628 8.8 L 13.828 12 L 10.628 15.2 Z"
        fill="#1E232F"
        transform="matrix(-1, 0, 0, -1, 23.999001, 24)"
      />
    </SvgIcon>
  )
}

export default TVICircleLeftIcon
