import Slider, { SliderProps } from '@mui/material/Slider'
import { FC, useRef } from 'react'

const defaultOptions: Partial<ZoomSliderProps> = {
  min: 0,
  max: 100,
  track: false,
  size: 'small',
  marks: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 50,
      label: 'Zoom',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
}

export type ZoomSliderProps = Omit<SliderProps, 'value' | 'onChange'> & {
  value?: number
  onChange(value: number): void
}

const ZoomSlider: FC<ZoomSliderProps> = (props) => {
  const options: ZoomSliderProps = { ...defaultOptions, ...props }
  const valueRef = useRef<number>()
  const handleChange = (e: Event, newValue: number | number[]) => {
    if (!props.onChange) return
    if (valueRef.current === newValue) return
    valueRef.current = newValue as number
    props.onChange(newValue as number)
  }

  return <Slider sx={{ width: '130px' }} {...options} onChange={handleChange} />
}

export default ZoomSlider
