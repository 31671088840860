import { Brand } from '@tvi/types/brand'
import { Client } from '@tvi/types/client'
import TviApiService from '../../TviApiService'

export type GetClientsResponse = Client[]
export type GetClientResponse = Client
export type InitiateClientResponse = { clients: Client[]; brands: Brand[] }

export interface ClientEndpoints {
  getClients(): Promise<GetClientsResponse>
  getClient(args: { id: number }): Promise<GetClientResponse>
  initiateClient(args: { id: number }): Promise<InitiateClientResponse>
}

export default class ClientService
  extends TviApiService
  implements ClientEndpoints
{
  async getClients(): Promise<GetClientsResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/clients`),
    }).then(this.takeOk)
  }

  async getClient({ id }: { id: number }): Promise<GetClientResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/clients/${id}`),
    }).then(this.takeOk)
  }

  async initiateClient({
    id,
    name = '',
  }: {
    id: number
    name: string
  }): Promise<InitiateClientResponse> {
    return this.request({
      method: 'POST',
      url: this.url(`/clients/${id}/brands`),
      body: name
        ? {
            brand: { name },
          }
        : {},
    }).then(this.takeOk)
  }
}
