import { BoxProps } from '@mui/material/Box'
import Card, { CardProps as MuiCardProps } from '@mui/material/Card'
import CardActions, { CardActionsProps } from '@mui/material/CardActions'
import CardContent, { CardContentProps } from '@mui/material/CardContent'
import MuiCardMedia, {
  CardMediaProps,
  CardMediaTypeMap,
} from '@mui/material/CardMedia'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import Typography from '@mui/material/Typography'
import { FC, ReactElement, ReactNode } from 'react'
import { CardHeader, ImgContainer, Progress, Title } from './MediaCard.styled'

const PROGRESS_START_POINT = 0
const PROGRESS_END_POINT = 100

export type CardProps = {
  image: string
  progress?: number | ReactNode
  header?: string | ReactNode
  title?: string | ReactNode
  info?: string | ReactNode
  actions?: string | ReactNode
  headerProps?: BoxProps
  imageProps?: CardMediaProps
  infoProps?: CardContentProps
  actionsProps?: CardActionsProps
  // prettier-ignore
  CardMedia?: 
    | OverridableComponent<CardMediaTypeMap<{}, 'div'>> &
        ReactElement<CardMediaProps> 
    | ReactElement
}

export type MediaCardProps = CardProps & Omit<MuiCardProps, keyof CardProps>

const MediaCard: FC<MediaCardProps> = ({
  progress,
  header,
  image,
  title,
  info,
  actions,
  headerProps,
  imageProps,
  infoProps,
  actionsProps,
  CardMedia,
  ...rest
}) => (
  <Card
    {...rest}
    sx={{
      width: 168,
      boxShadow: 'unset',
      borderRadius: 0,
      background: 'transparent',
      ...rest.sx,
    }}
  >
    <ImgContainer>
      {Boolean(headerProps?.children || header) && (
        <CardHeader {...headerProps} sx={{ padding: 0, ...headerProps?.sx }}>
          {header}
        </CardHeader>
      )}
      {CardMedia ? (
        CardMedia
      ) : (
        <MuiCardMedia
          component="img"
          height="94"
          image={image}
          {...imageProps}
        />
      )}
      {/* If progress == Number */}
      {Number.isFinite(progress as any) && (
        <Progress
          style={{
            width: `${
              isFinite(Number(progress))
                ? Math.min(Number(progress), PROGRESS_END_POINT)
                : PROGRESS_START_POINT
            }%`,
          }}
        />
      )}
      {/* If component == ReactNode */}
      {!Number.isFinite(progress as any) && progress !== undefined && (
        <>{progress}</>
      )}
    </ImgContainer>
    {Boolean(infoProps?.children || title || info) && (
      <CardContent
        {...infoProps}
        sx={{ padding: 0, paddingTop: 10, ...infoProps?.sx }}
      >
        {typeof title === 'string' ? (
          <Title variant="subtitle2">{title}</Title>
        ) : (
          title
        )}
        {typeof info === 'string' ? (
          <Typography variant="body2">{info}</Typography>
        ) : (
          info
        )}
      </CardContent>
    )}
    {Boolean(actionsProps?.children || actions) && (
      <CardActions
        disableSpacing
        {...actionsProps}
        sx={{ padding: 0, ...actionsProps?.sx }}
      >
        {actions}
      </CardActions>
    )}
  </Card>
)

export default MediaCard
