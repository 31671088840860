import AppsIcon from '@mui/icons-material/Apps'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { FC, useEffect, useRef, useState } from 'react'
import { TVIListIcon } from '../icons'

export type ViewType = 'list' | 'grid'

export type ToggleViewProps = {
  value?: ViewType
  defaultValue?: ViewType
  onChange(view: ViewType): void
}

const iconActive = {
  fontSize: 36,
  color: '#4DB0C2',
}

const iconNotActive = {
  ...iconActive,
  color: '#ffffff',
}

const ToggleView: FC<ToggleViewProps> = ({
  value,
  defaultValue = 'grid',
  onChange,
}) => {
  const shouldEmit = useRef<boolean>(false)
  const [view, setView] = useState<ViewType>(defaultValue)

  useEffect(() => {
    if (!value) return
    setView(value!)
  }, [value])

  useEffect(() => {
    if (!shouldEmit.current) {
      shouldEmit.current = true
      return
    }
    onChange(view)
  }, [view, onChange])

  const handleChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    nextView: ViewType
  ) => {
    if (!nextView) return
    setView(nextView)
  }

  return (
    <ToggleButtonGroup value={view} exclusive onChange={handleChange}>
      <ToggleButton value="grid" aria-label="grid" sx={{ padding: '5px' }}>
        <AppsIcon sx={view === 'grid' ? iconActive : iconNotActive} />
      </ToggleButton>
      <ToggleButton value="list" aria-label="list" sx={{ padding: '5px' }}>
        <TVIListIcon sx={view === 'list' ? iconActive : iconNotActive} />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default ToggleView
