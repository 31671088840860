import { WithSession } from '@tvi/types/api'

export type RequestConfig = {
  url: string
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD'
  body?: any
  params?: { [key: string | number]: any }
  headers?: { [key: string | number]: any }
  onUploadProgress?: (progressEvent: any) => void
  onDownloadProgress?: (progressEvent: any) => void
}

abstract class TviApiService {
  private readonly baseUrl: string
  private readonly requestInstance: any

  constructor(baseUrl: string, requestInstance: any) {
    this.baseUrl = baseUrl
    this.requestInstance = requestInstance
  }

  protected url(pathEndpoint: string) {
    return `${this.baseUrl}${pathEndpoint}`
  }

  protected async request<T = any>(config: RequestConfig): Promise<T> {
    return this.requestInstance(config)
  }

  protected takeOk<T = any>(response: any): T {
    const { data } = response
    if (response.status < 300) {
      return data.data || data
    }
    // only throw Errors
    throw new Error(data)
  }

  protected withSession<T = any>(response: any): WithSession<T> {
    const session = {
      accessToken: response.headers['access-token'],
      expiry: response.headers['expiry'],
      uid: response.headers['uid'],
      client: response.headers['client'],
      tokenType: response.headers['token-type'],
    }
    if (response.status < 300) {
      return {
        session,
        data: response.data.data,
      }
    }
    throw response.data
  }
}

export default TviApiService
