import {
  Paper,
  PaperProps,
  SxProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { CSSProperties, FC } from 'react'
import TVIWaveformIcon from '../icons/TVIWaveformIcon'

const trimTextStyles: CSSProperties = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '100%',
}

const styles: { [key: string]: CSSProperties } = {
  grid: {
    paddingRight: 10,
    paddingLeft: 10,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '2px',
    background: '#1d212b',
  },
  list: {
    width: '100%',
    paddingRight: 10,
    paddingLeft: 10,
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '2px',
    background: '#1d212b',
  },
}

export type AudioCardViewProps = {
  sxText?: SxProps
  sxIcon?: SxProps
  text?: string
  trimText?: boolean
  color?: string
  type?: 'grid' | 'list'
  variant?: TypographyProps['variant']
} & PaperProps

const AudioCardView: FC<AudioCardViewProps> = ({
  sx,
  sxText,
  sxIcon,
  text,
  color = 'white',
  type = 'grid',
  variant = 'subtitle2' as Variant,
  trimText = false,
  ...rest
}) => {
  return (
    <Paper {...rest} sx={{ ...styles[type], ...sx }}>
      <TVIWaveformIcon
        htmlColor={color}
        sx={{
          marginRight: type === 'list' ? 10 : 0,
          ...sxIcon,
        }}
      />
      <Typography
        component="div"
        variant={variant}
        color={color}
        sx={
          {
            fontSize: '8px',
            fontWeight: 500,
            flexShrink: 0,
            ...sxText,
            ...(trimText ? trimTextStyles : {}),
          } as SxProps
        }
      >
        {text}
      </Typography>
    </Paper>
  )
}

export default AudioCardView
