import { Box } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import { omit, pick } from 'lodash'
import { FC, ReactElement } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import DropzoneView from '../views/DropzoneView'

const dropzoneOptionKeys = [
  'accept',
  'minSize',
  'maxSize',
  'maxFiles',
  'preventDropOnDocument',
  'noClick',
  'noKeyboard',
  'noDrag',
  'noDragEventsBubbling',
  'disabled',
  'onDrop',
  'onDropAccepted',
  'onDropRejected',
  'getFilesFromEvent',
  'onFileDialogCancel',
  'validator',
]

const defaultViewRender = ({ open }: ViewRenderProps) => {
  return <DropzoneView onClick={open} />
}

export type ViewRenderProps = {
  open(): void
  isFocused: boolean
  isDragActive: boolean
  isDragAccept: boolean
  isDragReject: boolean
  isFileDialogActive: boolean
}

export type DropzoneProps = {
  viewRender?(props: ViewRenderProps): ReactElement
} & DropzoneOptions &
  BoxProps

const Dropzone: FC<DropzoneProps> = ({
  viewRender = defaultViewRender,
  ...rest
}) => {
  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFileDialogActive,
  } = useDropzone({
    noClick: true,
    ...pick(rest, dropzoneOptionKeys),
  })

  return (
    <Box {...getRootProps(omit(rest, ...dropzoneOptionKeys))}>
      <input {...getInputProps()} />
      {viewRender({
        open,
        isFocused,
        isDragActive,
        isDragAccept,
        isDragReject,
        isFileDialogActive,
      })}
    </Box>
  )
}

export default Dropzone
