import { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

export interface VideoPlayerProps {
  url?: string | string[]
  config: { [key: string]: any }
  light?: string | boolean
  muted?: boolean
  loop?: boolean
  volume?: number | undefined
  background?: string
  style?: CSSProperties
  microProgress?: number
  onInit?(player?: ReactPlayer): void
  onProgress?(e?: any): void
  onSeek?(time?: number): void
}

const identityFunc = () => void 0

const VideoPlayer: FC<VideoPlayerProps> = ({
  url,
  onInit,
  volume = undefined,
  loop = false,
  light = false,
  muted = true,
  microProgress = 200,
  onProgress = () => void 0,
  onSeek = () => void 0,
  style = { outline: 'none', border: 0, boxShadow: 'none' },
  config = {},
}) => {
  const playerRef = useRef<ReactPlayer>(null)
  const [prevTime, setPrevTime] = useState<number>()

  // check for more incremental updates than VideoPlayer
  // currently does (about 1 sec)
  useEffect(() => {
    if (!playerRef.current || microProgress === 0) return

    const intv = setInterval(() => {
      const time = playerRef.current?.getCurrentTime() || 0
      if (!Number.isFinite(time) || prevTime === time) return

      onProgress({ playedSeconds: time })
      setPrevTime(time)
    }, microProgress)

    return () => clearInterval(intv)
  }, [microProgress, prevTime, playerRef])

  return (
    <ReactPlayer
      style={style}
      onReady={onInit}
      onProgress={!microProgress ? onProgress : identityFunc}
      onSeek={onSeek}
      controls={config?.controls || false}
      config={config}
      volume={volume}
      loop={loop}
      url={url}
      light={light}
      muted={muted}
      ref={playerRef}
    />
  )
}

export default VideoPlayer
