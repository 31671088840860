import MuiPopper from '@mui/material/Popper'
import { styled } from '@mui/material/styles'
import { SystemCssProperties } from '@mui/system'
import { TVITheme } from '../../theme'

export const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 7,
  width: '12px',
  height: '6px',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
})

type PopperWithArrowProps = {
  theme?: TVITheme
  arrow?: boolean
  zIndex?: number
  bg?:
    | SystemCssProperties['background']
    | ((theme: TVITheme) => SystemCssProperties['background'])
}

export const PopperWithArrow = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== 'arrow',
})(({ theme, bg, arrow, zIndex }: PopperWithArrowProps) => ({
  zIndex,
  '& > div': {
    position: 'relative',
  },
  '&[data-popper-placement*="bottom"]': {
    '& > div': {
      marginTop: arrow ? 16 : 0,
    },
    '& .MuiPopper-arrow': {
      zIndex,
      top: 0,
      left: 0,
      marginTop: 10,
      width: '12px',
      height: '6px',
      '&::before': {
        borderWidth: '0 6px 6px 6px',
        borderColor: `transparent transparent ${
          (typeof bg === 'function' ? bg(theme as TVITheme) : bg) ??
          theme!.palette.background.paper
        } transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    '& > div': {
      marginBottom: arrow ? 16 : 0,
    },
    '& .MuiPopper-arrow': {
      zIndex,
      bottom: 0,
      left: 0,
      marginBottom: 10,
      width: '12px',
      height: '6px',
      '&::before': {
        borderWidth: '6px 6px 0 6px',
        borderColor: `${
          bg ?? theme!.palette.background.paper
        } transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    '& > div': {
      marginLeft: arrow ? 16 : 0,
    },
    '& .MuiPopper-arrow': {
      zIndex,
      left: 0,
      marginLeft: 10,
      height: '12px',
      width: '6px',
      '&::before': {
        borderWidth: '6px 6px 6px 0',
        borderColor: `transparent ${
          bg ?? theme!.palette.background.paper
        } transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    '& > div': {
      marginRight: arrow ? 16 : 0,
    },
    '& .MuiPopper-arrow': {
      zIndex,
      right: 0,
      marginRight: 10,
      height: '12px',
      width: '6px',
      '&::before': {
        borderWidth: '6px 0 6px 6px',
        borderColor: `transparent transparent transparent ${
          bg ?? theme!.palette.background.paper
        }`,
      },
    },
  },
}))
