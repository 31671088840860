import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { FC } from 'react'

export type AccordionSummaryProps = {
  text?: string
} & MuiAccordionSummaryProps

export const AccordionSummary: FC<AccordionSummaryProps> = ({
  text,
  ...rest
}) => {
  return (
    <MuiAccordionSummary
      expandIcon={
        <KeyboardArrowDownIcon
          color="primary"
          sx={{
            fontSize: 32,
          }}
        />
      }
      {...rest}
    >
      <Typography typography="h6">{text}</Typography>
    </MuiAccordionSummary>
  )
}

export type AccordionProps = { text: string } & MuiAccordionProps

const Accordion: FC<AccordionProps> = ({ text, children, ...rest }) => {
  return (
    <MuiAccordion {...rest}>
      <AccordionSummary text={text} />
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion
