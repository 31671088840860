import { createContext, useMemo, useState } from 'react'

export type FilmstripScaleCtx = (value: number) => number

export const filmstripScaleCtx = createContext<FilmstripScaleCtx>(
  (value) => value
)

export const FilmstripScaleConsumer = filmstripScaleCtx.Consumer
export const FilmstripScaleProvider = filmstripScaleCtx.Provider

export type FilmstripScaleOptions = {
  scalable?: boolean
  scaleMinValue?: number
  scaleMaxValue?: number
}

export type UseFilmstripScale = {
  setScale(value: number): void
  scaleFn(value: number): number
}

export const useFilmstripScale = ({
  scalable,
  scaleMinValue = 0,
  scaleMaxValue = Infinity,
}: FilmstripScaleOptions): UseFilmstripScale => {
  const ONE_HUNDRED_PERCENT = 100
  const [scaleValue, setScaleValue] = useState<number>(ONE_HUNDRED_PERCENT)
  return useMemo(
    () => ({
      setScale: setScaleValue,
      scaleFn: (value: number) => {
        const scaleOn = scaleValue / ONE_HUNDRED_PERCENT
        const res = scalable
          ? Math.max(Math.min(value * scaleOn, scaleMaxValue), scaleMinValue)
          : value
        return res
      },
    }),
    [scalable, scaleValue, scaleMaxValue, scaleMinValue]
  )
}
