import { Brand } from '@tvi/types/brand'
import TviApiService from '../../TviApiService'

export type GetBrandsArgs = {
  clientId: number
}
export type GetBrandArgs = {
  id: number
}
export type GetBrandsResponse = Partial<Brand>[]
export type GetBrandResponse = Brand

export interface BrandEndpoints {
  getBrands(args: GetBrandsArgs): Promise<GetBrandsResponse>
  getBrand(args: GetBrandArgs): Promise<GetBrandResponse>
}

export default class BrandService
  extends TviApiService
  implements BrandEndpoints
{
  async getBrands({ clientId }: GetBrandsArgs): Promise<GetBrandsResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/clients/${clientId}/brands`),
    }).then(this.takeOk)
  }

  async getBrand({ id }: GetBrandArgs): Promise<GetBrandResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/clients/brands/${id}`),
    }).then(this.takeOk)
  }
}
