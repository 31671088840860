import { Close } from '@mui/icons-material'
import { Box, IconButton, IconButtonProps } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import { FC, ReactNode } from 'react'

export type ClosableProps = {
  icon?: ReactNode
  top?: string | number
  bottom?: string | number
  right?: string | number
  left?: string | number
  size?: string | number
  color?: string
  fit?: boolean
  onClick?: IconButtonProps['onClick']
} & BoxProps

const Closable: FC<ClosableProps> = ({
  children,
  icon,
  top = 10,
  right = 10,
  bottom,
  left,
  color,
  size = 16,
  fit = false,
  onClick,
  ...rest
}) => {
  return (
    <Box
      aria-label="closable"
      {...rest}
      sx={{
        width: fit ? 'fit-content' : undefined,
        position: 'relative',
        ...rest.sx,
      }}
    >
      <IconButton
        onClick={onClick}
        sx={{
          position: 'absolute',
          top,
          bottom,
          right,
          left,
        }}
      >
        {icon ?? (
          <Close
            viewBox="4 4 16 16"
            sx={(theme) => ({
              color: color ?? theme?.palette?.primary?.main,
              fontSize: size,
            })}
          />
        )}
      </IconButton>
      {children}
    </Box>
  )
}

export default Closable
