import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { TimelineEvent } from './AudioTimeline'
import { scaleTimeToPx } from './audioTimeline.utils'
import {
  AudioTrimContainer,
  TrimEdge,
  TRIM_EDGE_WIDTH,
} from './AudioTrim.styled'

type EdgeName = 'left' | 'right'

export type AudioTrimProps = {
  id: string
  width: number
  startPoint: number
  endPoint: number
  startPointLimit?: number
  endPointLimit?: number
  onTrimStart?(id: string): void
  onTrimEnd?(trimEvent: TimelineEvent): void
}

const AudioTrim: FC<AudioTrimProps> = ({
  id,
  width,
  startPoint,
  endPoint,
  onTrimStart,
  onTrimEnd,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const innerWrapperRef = useRef<HTMLDivElement>(null)
  const trimmedWidthRef = useRef<number>(width)
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false)
  const activeEdgeRef = useRef<EdgeName>('left')
  const startPositionRef = useRef<number>(NaN)
  const endPositionRef = useRef<number>(NaN)
  const clientLeftOffsetRef = useRef<number>(NaN)
  const leftOffsetRef = useRef<number>(NaN)
  const negativeMarginLeftRef = useRef<number>(NaN)
  const scale = useMemo(
    () => scaleTimeToPx(endPoint - startPoint, width),
    [endPoint - startPoint, width]
  )

  useEffect(() => {
    containerRef.current!.style.left = '0px'
    innerWrapperRef.current!.style.marginLeft = '0px'
    containerRef.current!.style.width = width + 'px'
  }, [startPoint, endPoint, width])

  const handleTrimStart = (edgeName: EdgeName) => {
    setIsMouseDown(true)
    activeEdgeRef.current = edgeName
    startPositionRef.current = NaN
    endPositionRef.current = NaN
    clientLeftOffsetRef.current = NaN
    leftOffsetRef.current = NaN
    negativeMarginLeftRef.current = NaN
    trimmedWidthRef.current = containerRef.current!.clientWidth
    onTrimStart?.(id)
  }

  useEffect(() => {
    if (!isMouseDown) return
    const handleTrimEnd = () => {
      setIsMouseDown(false)
      document.body.removeEventListener('mouseup', handleTrimEnd)

      if (isNaN(endPositionRef.current) || isNaN(startPositionRef.current)) {
        return
      }

      const trimDelta = scale.pxToTime(
        endPositionRef.current - startPositionRef.current
      )

      /* don't let it stretch */
      if (activeEdgeRef.current === 'left' && trimDelta < 0) return
      if (activeEdgeRef.current === 'right' && trimDelta > 0) return
      onTrimEnd?.({
        id,
        startPoint,
        endPoint,
        nextStartPoint:
          activeEdgeRef.current === 'left'
            ? startPoint + trimDelta
            : startPoint,
        nextEndPoint:
          activeEdgeRef.current === 'right' ? endPoint + trimDelta : endPoint,
      })
    }

    document.body.addEventListener('mouseup', handleTrimEnd)
  }, [isMouseDown])

  useEffect(() => {
    if (!isMouseDown) return

    let prevClientX: number = NaN
    const handleTrim = (e: MouseEvent) => {
      if (e.clientX === prevClientX) return
      endPositionRef.current = e.clientX
      if (isNaN(startPositionRef.current)) {
        startPositionRef.current = e.clientX
      }
      if (isNaN(clientLeftOffsetRef.current)) {
        const { x } = containerRef.current!.getBoundingClientRect()
        clientLeftOffsetRef.current = x
      }
      if (isNaN(leftOffsetRef.current)) {
        leftOffsetRef.current = containerRef.current!.offsetLeft
      }
      if (isNaN(negativeMarginLeftRef.current)) {
        negativeMarginLeftRef.current = parseInt(
          innerWrapperRef.current!.style.marginLeft || '0',
          10
        )
      }

      const minWidth = TRIM_EDGE_WIDTH * 2
      const maxOffset = width - minWidth
      const diff =
        activeEdgeRef.current === 'left'
          ? startPositionRef.current - e.clientX
          : e.clientX - startPositionRef.current

      const overLimitOffset =
        activeEdgeRef.current === 'left'
          ? -Math.min(leftOffsetRef.current - diff, 0)
          : 0

      let nextWidth = Math.max(
        minWidth,
        Math.min(width, trimmedWidthRef.current + diff - overLimitOffset)
      )

      const nextLeftOffset = Math.min(
        maxOffset,
        Math.max(0, leftOffsetRef.current - diff)
      )

      const nextMarginOffset = Math.min(
        0,
        Math.max(-maxOffset, negativeMarginLeftRef.current + diff)
      )

      const currentLeftOffset = parseInt(
        containerRef.current!.style.left || '0',
        10
      )

      const isStretching =
        activeEdgeRef.current === 'left' ? diff > 0 : diff > 0

      const isLeftLimit = !currentLeftOffset
      if (activeEdgeRef.current === 'left' && isLeftLimit && isStretching) {
        return
      }

      const isRightLimit = currentLeftOffset + nextWidth > width
      if (activeEdgeRef.current === 'right' && isRightLimit && isStretching) {
        nextWidth = width - currentLeftOffset
      }

      if (activeEdgeRef.current === 'left' && nextWidth > minWidth) {
        containerRef.current!.style.left = nextLeftOffset + 'px'
        innerWrapperRef.current!.style.marginLeft = nextMarginOffset + 'px'
      }

      containerRef.current!.style.width = nextWidth + 'px'
    }

    document.body.addEventListener('mousemove', handleTrim)
    return () => document.body.removeEventListener('mousemove', handleTrim)
  }, [width, isMouseDown])

  return (
    <AudioTrimContainer className="audio-trim-container" ref={containerRef}>
      <TrimEdge left onMouseDown={() => handleTrimStart('left')} />
      <div
        className="audio-trim"
        ref={innerWrapperRef}
        style={{ width, height: '100%' }}
      >
        {children}
      </div>
      <TrimEdge right onMouseDown={() => handleTrimStart('right')} />
    </AudioTrimContainer>
  )
}

export default AudioTrim
