import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Button, { ButtonProps } from '@mui/material/Button'
import { FC } from 'react'

export type ButtonBackProps = {
  text: string
} & ButtonProps

const ButtonBack: FC<ButtonBackProps> = ({ text, ...rest }) => {
  return (
    <Button {...rest} variant="text" startIcon={<ArrowBackIosIcon />}>
      {text}
    </Button>
  )
}

export default ButtonBack
