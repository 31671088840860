import { Asset, UploadStorageParams } from '@tvi/types/asset'
import { nanoid } from 'nanoid'
import { StatusResponse } from '../../index'
import TviApiService from '../../TviApiService'

export type StoreCloudUploadArgs = {
  uploadUri: string
  file: File
  progress?: (progressEvent: any) => void
}

export type PostUploadArgs = {
  uri: string
  fileName: string
  mimeType: string
  projectId: number
  unique?: boolean
}

export interface AssetEndpoints {
  initiateUploadAsset(mimeType: Asset['mimeType']): Promise<UploadStorageParams>
  deleteAsset(id: number): Promise<StatusResponse>
  getAssets(projectId: number): Promise<Asset[]>
  postUpload(args: PostUploadArgs): Promise<Asset>
  storeCloudUpload(args: StoreCloudUploadArgs): Promise<any>
}

export default class AssetService
  extends TviApiService
  implements AssetEndpoints
{
  initiateUploadAsset(
    mimeType: Asset['mimeType']
  ): Promise<UploadStorageParams> {
    return this.request({
      method: 'POST',
      url: this.url('/assets/initiate_upload'),
      body: { mimeType },
    }).then(this.takeOk)
  }

  deleteAsset(id: number): Promise<StatusResponse> {
    return this.request({
      method: 'DELETE',
      url: this.url(`/assets/${id}`),
    }).then(this.takeOk)
  }

  getAssets(projectId: number): Promise<Asset[]> {
    return this.request({
      method: 'GET',
      url: this.url(`/projects/${projectId}/assets`),
    }).then(this.takeOk)
  }

  postUpload({
    uri,
    fileName,
    mimeType,
    projectId,
    unique = true,
  }: PostUploadArgs): Promise<Asset> {
    return this.request({
      method: 'POST',
      url: this.url(`/projects/${projectId}/assets`),
      body: {
        // asset names in a bucket must be unique, if not will throw an error
        name: fileName + (unique ? nanoid() : ''),
        mimeType,
        uri,
      },
    }).then(this.takeOk)
  }

  storeCloudUpload({
    uploadUri,
    file,
    progress,
  }: StoreCloudUploadArgs): Promise<any> {
    return this.request({
      method: 'PUT',
      url: uploadUri,
      body: file,
      headers: {
        'Content-Type': file!.type,
      },
      onUploadProgress: progress,
    }).then(this.takeOk)
  }
}
