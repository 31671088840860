import {
  ServiceProfile,
  ServiceProfileServices,
} from '@tvi/types/serviceProfile'
import TviApiService from '../../TviApiService'

export type GetServiceProfilesArgs = {
  brandId: number
  service?: ServiceProfileServices
}

export type PostServiceProfilesArgs = GetServiceProfilesArgs & {
  email: string
  name?: string
}

export type PostServiceProfilesResponse = {
  id: number
  email: string
  authorizationUri: string
  service: 'youtube'
}

export type GetServiceProfilesResponse = ServiceProfile[]

export interface ServiceProfilesEndpoints {
  getServiceProfiles(
    args: GetServiceProfilesArgs
  ): Promise<GetServiceProfilesResponse>
  createServiceProfile(
    args: PostServiceProfilesArgs
  ): Promise<PostServiceProfilesResponse>
  deleteServiceProfile(args: { id: number }): Promise<void>
  updateServiceProfile(args: { profile: ServiceProfile }): Promise<void>
}

export default class ServiceProfilesService
  extends TviApiService
  implements ServiceProfilesEndpoints
{
  async getServiceProfiles({
    brandId,
    service = undefined,
  }: GetServiceProfilesArgs): Promise<GetServiceProfilesResponse> {
    if (service) {
      return this.request({
        method: 'GET',
        url: this.url(`/brands/${brandId}/service_profiles/${service}`),
      }).then(this.takeOk)
    }
    return this.request({
      method: 'GET',
      url: this.url(`/brands/${brandId}/service_profiles`),
    }).then(this.takeOk)
  }

  async createServiceProfile({
    brandId,
    email,
    name,
    service = 'youtube',
  }: PostServiceProfilesArgs): Promise<PostServiceProfilesResponse> {
    return this.request({
      method: 'POST',
      url: this.url(`/brands/${brandId}/service_profiles/${service}`),
      body: {
        service_profile: {
          email,
          name,
        },
      },
    }).then(this.takeOk)
  }

  async deleteServiceProfile({ id }: { id: number }): Promise<void> {
    return this.request({
      method: 'DELETE',
      url: this.url(`/service_profiles/${id}`),
    }).then(this.takeOk)
  }

  async updateServiceProfile({
    profile,
  }: {
    profile: ServiceProfile
  }): Promise<void> {
    return this.request({
      method: 'PUT',
      url: this.url(`/service_profiles/${profile.id}`),
      body: {
        service_profile: {
          name: profile.name,
        },
      },
    }).then(this.takeOk)
  }
}
