import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles/createTheme'
import Typography from '@mui/material/Typography'

export const ImgContainer = styled.div`
  width: 100%;
  position: relative;
`

export const CardHeader = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const Progress = styled.div(({ theme }) => ({
  height: '4px',
  position: 'absolute',
  bottom: 0,
  backgroundColor: (theme as Theme).palette.primary.main,
}))

export const Title = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
