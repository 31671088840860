import { Paper, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { FC, MouseEvent } from 'react'
import Button from '../button'
import { TVICloudIcon } from '../icons'

export type DropzoneViewProps = {
  isActive?: boolean
  text?: string
  bg?: string
  bgActive?: string
  borderColor?: string
  sx?: SxProps
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const DropzoneView: FC<DropzoneViewProps> = ({
  isActive,
  children,
  text = 'Drag and drop files to upload',
  sx,
  onClick,
  bg = '#292E39',
  bgActive = '#22252E',
  borderColor = '#575d6e',
}) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
        background: isActive ? bgActive : bg,
        border: isActive ? '1px dashed #575d6e' : undefined,
        ...sx,
      }}
    >
      <Typography variant="body2">
        <TVICloudIcon
          viewBox="4 4 16 16"
          sx={{
            fontSize: isActive ? 111 : 69,
          }}
        />
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        {text}
      </Typography>
      {!isActive && (
        <Button
          variant="contained"
          size="small"
          sx={{
            paddingX: 30,
            marginTop: 14,
            fontSize: 14,
            fontWeight: 500,
          }}
          onClick={onClick}
        >
          <TVICloudIcon
            viewBox="4 4 16 16"
            sx={{ marginRight: 10, fontSize: 21 }}
          />
          <span>or choose files</span>
        </Button>
      )}
      {children}
    </Paper>
  )
}

export default DropzoneView
