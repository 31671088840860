import { SvgIconProps } from '@mui/material'
import withTheme from '../../utils/withTheme'
/* icons */
// organize-imports-ignore
import CheckIcon from './TVICheckIcon'
import CircleLeftIcon from './TVICircleLeftIcon'
import CircleRightIcon from './TVICircleRightIcon'
import ListIcon from './TVIListIcon'
import SearchIcon from './TVISearchIcon'
import TVIYoutube from './TVIYoutubeIcon'
import TVICircleCheck from './TVICircleCheckIcon'
import TVIWaveform from './TVIWaveformIcon'
import TVICloud from './TVICloudIcon'
/* icons END */

export type { SvgIconProps } from '@mui/material'

export const TVICheckIcon = withTheme<SvgIconProps>(CheckIcon, 'TVICheckIcon')
export const TVILineHorizontalIcon = withTheme<SvgIconProps>(
  CheckIcon,
  'TVILineHorizontalIcon'
)
export const TVICircleLeftIcon = withTheme<SvgIconProps>(
  CircleLeftIcon,
  'TVICircleLeftIcon'
)
export const TVICircleRightIcon = withTheme<SvgIconProps>(
  CircleRightIcon,
  'TVICircleRightIcon'
)
export const TVIListIcon = withTheme<SvgIconProps>(ListIcon, 'TVIListIcon')
export const TVISearchIcon = withTheme<SvgIconProps>(
  SearchIcon,
  'TVISearchIcon'
)
export const TVIYoutubeIcon = withTheme<SvgIconProps>(
  TVIYoutube,
  'TVIYoutubeIcon'
)
export const TVICircleCheckIcon = withTheme<SvgIconProps>(
  TVICircleCheck,
  'TVICircleCheckIcon'
)
export const TVIWaveformIcon = withTheme<SvgIconProps>(
  TVIWaveform,
  'TVIWaveformIcon'
)
export const TVICloudIcon = withTheme<SvgIconProps>(TVICloud, 'TVICloudIcon')
