import WaveformData from 'waveform-data'

type WaveDataOptions = {
  /**
   * Number of pixels */
  width?: number
  /**
   * Number of audio samples per pixel. Default: 1
   * */
  samplesPerPixel?: number
  /**
   * Amplitude scale. Default: 1.0
   * */
  amplitudeScale?: number
}

const waveDataOptions = {
  samplesPerPixel: 1,
  amplitudeScale: 1,
}

export const getWaveformData = async (
  arrayBuffer: ArrayBuffer,
  options: WaveDataOptions = waveDataOptions
): Promise<WaveformData> => {
  const audioContext = new AudioContext()
  const audioOptions = {
    scale: options.samplesPerPixel ?? waveDataOptions.samplesPerPixel,
    amplitude_scale: options.amplitudeScale ?? waveDataOptions.amplitudeScale,
    audio_context: audioContext,
    array_buffer: arrayBuffer.slice(0),
  }

  const waveform: WaveformData = await new Promise((resolve, reject) => {
    WaveformData.createFromAudio(audioOptions, (err, waveform) => {
      if (err) return reject(err)
      resolve(
        !options?.width ? waveform : waveform.resample({ width: options.width })
      )
    })
  })

  return waveform
}

export type DrawWaveformOptions = {
  height: number
  upperPoints?: number[]
  lowerPoints?: number[]
  waveColor?: string
}

const defaultDrawWaveformOptions = {
  waveColor: 'black',
  upperPoints: [],
  lowerPoints: [],
}

export const drawWaveform = (
  ctx: CanvasRenderingContext2D,
  options: DrawWaveformOptions
) => {
  const LINE_WIDTH = 0.5
  const { height, upperPoints, lowerPoints, waveColor }: DrawWaveformOptions = {
    ...defaultDrawWaveformOptions,
    ...options,
  }
  const length = Math.max(upperPoints.length, lowerPoints.length)
  const scaleY = (amplitude: number, height: number): number => {
    const range = 256
    const offset = 128
    return height - ((amplitude + offset) * height) / range
  }
  ctx.beginPath()
  ctx.strokeStyle = waveColor ?? defaultDrawWaveformOptions.waveColor

  for (let x = 0; x < length; x++) {
    const upper =
      upperPoints[x] !== undefined
        ? scaleY(upperPoints[x], height) + LINE_WIDTH
        : 0
    const lower =
      lowerPoints[x] !== undefined
        ? scaleY(lowerPoints[x], height) + LINE_WIDTH
        : 0
    ctx.moveTo(x + LINE_WIDTH, upper)
    ctx.lineTo(x + LINE_WIDTH, lower)
  }

  ctx.closePath()
  ctx.stroke()
}
