import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

const TVIYoutubeIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M 17.066 6.158 C 14.666 5.958 9.333 5.958 6.933 6.158 C 4.333 6.358 4 7.891 4 12.025 C 4 16.158 4.333 17.691 6.933 17.891 C 9.333 18.025 14.666 18.025 17.066 17.891 C 19.666 17.758 20 16.158 20 12.025 C 20 7.891 19.666 6.358 17.066 6.158 Z M 10 14.691 L 10 9.358 L 15.333 12.025 L 10 14.691 Z" />
    </SvgIcon>
  )
}

export default TVIYoutubeIcon
