import { createContext, useMemo, useState } from 'react'

export type SelectedId = string | number | null

export type FilmstripItemStateOptions = {
  onSelect?(selected: SelectedId): void
}

export type FilmstripItemStateCtx = {
  selected: SelectedId
  hovered: SelectedId
  setSelected(selected: SelectedId): void
  setHovered(hovered: SelectedId): void
}

export const filmstripItemStateCtx = createContext<FilmstripItemStateCtx>({
  selected: null,
  hovered: null,
  setSelected: () => {},
  setHovered: () => {},
})

export const FilmstripItemStateConsumer = filmstripItemStateCtx.Consumer
export const FilmstripItemStateProvider = filmstripItemStateCtx.Provider

export const useFilmstripItemState = (): FilmstripItemStateCtx => {
  const [selected, setSelected] = useState<SelectedId>(null)
  const [hovered, setHovered] = useState<SelectedId>(null)
  return useMemo(() => {
    return {
      selected,
      hovered,
      setSelected,
      setHovered,
    }
  }, [selected, hovered])
}
