import Typography from '@mui/material/Typography'
import { FC, ReactNode } from 'react'
import spaces, { SpaceType } from '../../utils/spaces'

export type TagProps = {
  children?: ReactNode
  text?: string | number
  color?: string
  m?: SpaceType
}

const Tag: FC<TagProps> = ({ m, text, children, color = '#343944' }) => {
  return (
    <Typography
      component="span"
      sx={{
        ...spaces(m, 'margin'),
        display: 'inline-flex',
        padding: '3px 8px',
        color: '#8E9197',
        backgroundColor: color,
        fontSize: '12px',
        fontWeight: 500,
        borderRadius: '2px',
      }}
    >
      {text || children}
    </Typography>
  )
}

export default Tag
