import withTheme from '../../utils/withTheme'
import GroupControlsUnstyled, { GroupControlsProps } from './GroupControls'

const GroupControls = withTheme<GroupControlsProps>(
  GroupControlsUnstyled,
  'Input'
)

export type { GroupControlsProps } from './GroupControls'
export default GroupControls
