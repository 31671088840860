import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FC } from 'react'

export type TextBlock2Props = {
  title: string
} & BoxProps

const TextBlock2: FC<TextBlock2Props> = ({ title, children, ...rest }) => {
  return (
    <Box {...rest}>
      <Typography variant="h6">{title}</Typography>
      <Typography>{children}</Typography>
    </Box>
  )
}

export default TextBlock2
