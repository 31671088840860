import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper, { PopperProps } from '@mui/material/Popper'
import Typography from '@mui/material/Typography'
import { FC, ReactNode, useCallback, useRef, useState } from 'react'
import { Button } from '../../index'

export type UserMenuProps = Omit<PopperProps, 'open'> & {
  text: string
  items: ReactNode[]
  open?: boolean
}

const UserMenu: FC<UserMenuProps> = ({
  text,
  items,
  open = false,
  ...rest
}) => {
  const anchorRef = useRef<HTMLElement>()
  const [isOpen, setIsOpen] = useState<boolean>(open)
  const toggle = useCallback(() => setIsOpen((state) => !state), [])
  const close = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Button
        ref={anchorRef}
        variant="text"
        onClick={toggle}
        endIcon={
          isOpen ? (
            <ArrowDropUpIcon htmlColor="white" />
          ) : (
            <ArrowDropDownIcon htmlColor="white" />
          )
        }
      >
        <Typography variant="subtitle1">{text}</Typography>
      </Button>
      <Popper
        disablePortal
        open={isOpen}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        style={{ zIndex: 1 }}
        {...rest}
      >
        <Paper
          sx={{
            background: '#ffffff',
          }}
        >
          <ClickAwayListener onClickAway={close}>
            <MenuList dense autoFocusItem={open}>
              {items.map((item, index) => (
                <MenuItem dense key={index}>
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
}

export default UserMenu
