import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

const TVIWaveformIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M 1.5 12.86 L 0.5 12.86 L 0.5 12 L 1.5 12 L 1.5 12.86 Z M 23.5 12 L 22.5 12 L 22.5 12.86 L 23.5 12.86 L 23.5 12 Z M 3.5 11.14 L 2.5 11.14 L 2.5 13.72 L 3.5 13.72 L 3.5 11.14 Z M 21.5 11.14 L 20.5 11.14 L 20.5 13.72 L 21.5 13.72 L 21.5 11.14 Z M 7.5 11.14 L 6.5 11.14 L 6.5 13.72 L 7.5 13.72 L 7.5 11.14 Z M 17.5 10.28 L 16.5 10.28 L 16.5 14.58 L 17.5 14.58 L 17.5 10.28 Z M 5.5 10.28 L 4.5 10.28 L 4.5 14.58 L 5.5 14.58 L 5.5 10.28 Z M 19.5 9.42 L 18.5 9.42 L 18.5 15.44 L 19.5 15.44 L 19.5 9.42 Z M 9.5 9.42 L 8.5 9.42 L 8.5 15.44 L 9.5 15.44 L 9.5 9.42 Z M 11.5 7.7 L 10.5 7.7 L 10.5 16.3 L 11.5 16.3 L 11.5 7.7 Z M 15.5 7.7 L 14.5 7.7 L 14.5 16.3 L 15.5 16.3 L 15.5 7.7 Z M 13.5 5.98 L 12.5 5.98 L 12.5 18.02 L 13.5 18.02 L 13.5 5.98 Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default TVIWaveformIcon
