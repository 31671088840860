import {
  LoginResponse,
  ResetPasswordType,
  SignInType,
  UserRegistration,
  UserRegistrationResponse,
} from '@tvi/types/api'
import TviApiService from '../../TviApiService'

export interface AuthEndpoints {
  signUp(body: UserRegistration): Promise<UserRegistrationResponse>
  signIn(body: SignInType): Promise<LoginResponse>
  signOut(): Promise<any>
  resetPassword(body: ResetPasswordType): Promise<any>
  userByUID({ uid }: { uid: string }): Promise<LoginResponse>
}

export default class AuthService
  extends TviApiService
  implements AuthEndpoints
{
  async signUp(body: UserRegistration): Promise<UserRegistrationResponse> {
    return this.request({
      method: 'POST',
      url: this.url('/auth'),
      body,
    }).then(this.takeOk)
  }

  async signIn(body: SignInType): Promise<LoginResponse> {
    return this.request({
      method: 'POST',
      url: this.url('/auth/sign_in'),
      body,
    }).then(this.takeOk)
  }

  async signOut(): Promise<UserRegistrationResponse> {
    return this.request({
      method: 'DELETE',
      url: this.url('/auth/sign_out'),
    }).then(this.takeOk)
  }

  async resetPassword(body: ResetPasswordType) {
    return this.request({
      method: 'POST',
      url: this.url('/auth/reset-password'),
      body,
    }).then(this.takeOk)
  }

  async userByUID({ uid }: { uid: string }): Promise<LoginResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/users/by_uid/${uid}`),
    }).then(this.takeOk)
  }
}
