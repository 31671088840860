import { createContext, FC } from 'react'

const RenderCaseCtx = createContext<any>(null)

export const RenderCase: FC<{ value: any }> = ({ value, children }) => {
  return (
    <RenderCaseCtx.Provider value={value}>{children}</RenderCaseCtx.Provider>
  )
}

export const Case: FC<{ value: any }> = ({ value, children }) => {
  return (
    <RenderCaseCtx.Consumer>
      {(caseValue) => (caseValue === value ? children : null)}
    </RenderCaseCtx.Consumer>
  )
}
