import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

const TVICloudIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 16.9 10.655 C 16.447 8.289 14.427 6.512 12 6.512 C 10.073 6.512 8.4 7.637 7.567 9.283 C 5.56 9.503 4 11.252 4 13.372 C 4 15.643 5.793 17.488 8 17.488 L 16.667 17.488 C 18.507 17.488 20 15.951 20 14.058 C 20 12.247 18.633 10.779 16.9 10.655 Z M 13.333 12.686 L 13.333 15.43 L 10.667 15.43 L 10.667 12.686 L 8.667 12.686 L 12 9.256 L 15.333 12.686 L 13.333 12.686 Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default TVICloudIcon
