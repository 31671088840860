import ReactPlayer from 'react-player'
import withTheme from '../../utils/withTheme'
import VideoPlayerUnstyled, { VideoPlayerProps } from './VideoPlayer'

const VideoPlayer = withTheme<VideoPlayerProps>(
  VideoPlayerUnstyled,
  'VideoPlayer'
)

export type VideoPlayerType = ReactPlayer
export type { VideoPlayerProps } from './VideoPlayer'
export default VideoPlayer
