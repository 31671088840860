import { FC, Fragment } from 'react'

export type GapProps = {
  number?: number
}

const Gap: FC<GapProps> = ({ number = 1 }) => (
  <>{Array(number).fill(<Fragment key="space">&nbsp;</Fragment>)}</>
)

export default Gap
