import { FormControlLabel } from '@mui/material'
import MuiCheckbox from '@mui/material/Checkbox'
import { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox/Checkbox'
import * as React from 'react'
import { FC } from 'react'
import { TVICheckIcon } from '../icons'
import TVILineHorizontalIcon from '../icons/TVILineHorizontalIcon'

type CheckboxSXExtendedProps = {
  sx?: {
    fontSize: string | number
  }
}

type LabelProps = {
  label?: React.ReactNode & (string | number | React.ReactElement)
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}

export type CheckboxProps = LabelProps &
  CheckboxSXExtendedProps &
  Omit<MuiCheckboxProps, 'icon' | 'checkedIcon'>

const Checkbox: FC<CheckboxProps> = ({
  label = '',
  labelPlacement = 'end',
  ...rest
}) => {
  return (
    <FormControlLabel
      componentsProps={{
        typography: {
          sx: {
            fontSize: rest.sx?.fontSize || '12px',
            fontWeight: 600,
          },
        },
      }}
      label={label}
      labelPlacement={labelPlacement}
      control={
        <MuiCheckbox
          {...rest}
          icon={<></>}
          indeterminateIcon={
            <TVILineHorizontalIcon
              sx={{
                fontSize: '18px',
                color: 'primary.contrastText',
              }}
            />
          }
          checkedIcon={
            <TVICheckIcon
              sx={{
                fontSize: '18px',
                color: 'primary.contrastText',
              }}
            />
          }
        />
      }
    />
  )
}

export default Checkbox
