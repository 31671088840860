import withTheme from '../../utils/withTheme'
import AccordionUnstyled, {
  AccordionProps,
  AccordionSummary as AccordionSummaryUnstyled,
  AccordionSummaryProps,
} from './Accordion'

const Accordion = withTheme<AccordionProps>(AccordionUnstyled, 'Accordion')
export const AccordionSummary = withTheme<AccordionSummaryProps>(
  AccordionSummaryUnstyled,
  'AccordionSummary'
)

export type { AccordionProps } from './Accordion'
export default Accordion
