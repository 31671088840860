import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material/styles/createTheme'

export const AudioTimeLineContainer = styled(Box)(
  (props: { width?: number; height: number; theme?: Theme }) => ({
    width: '100%',
    height: props.height,
    position: 'relative',
    boxSizing: 'border-box',
  })
)

export const AudioTimeLineTrack = styled('div')(
  (props: { width?: number; height: number; theme?: Theme }) => ({
    width: props.width,
    height: '100%',
    position: 'relative',
    boxSizing: 'border-box',
  })
)
