import CircularProgress from '@mui/material/CircularProgress'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { FC } from 'react'
import TVISearchIcon from '../icons/TVISearchIcon'

export type SearchProps = TextFieldProps & {
  loading?: boolean
}

const Search: FC<SearchProps> = ({ size, loading, disabled, ...props }) => {
  return (
    <TextField
      {...props}
      disabled={loading || disabled}
      InputProps={{
        startAdornment: loading ? (
          <CircularProgress size={size === 'small' ? 14 : 27} />
        ) : (
          <TVISearchIcon style={{ fontSize: size === 'small' ? 22 : 27 }} />
        ),
        style:
          size === 'small'
            ? {
                height: 24,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: loading ? 10 : 6,
                paddingRight: 10,
                fontSize: 12,
              }
            : {},
      }}
    />
  )
}

export default Search
