import Box from '@mui/material/Box'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { ElementType, FC } from 'react'

export type BreadcrumbItem = {
  text: string
  component?: ElementType
  props?: TypographyProps
}

export type BreadcrumbsProps = {
  /**
   * The default component that will be used to render breadcrumbs item
   **/
  component?: ElementType
  /**
   * One item represented by Typography component.
   * You can pass a breadcrumb item object with { text: string,
  component?: ElementType, props?: TypographyProps }, where:
   text: a text of breadcrumbs item;
   component: a component that represented an item of breadcrumbs;
   props: TypographyProps, - props of material ui Typography component;
   **/
  items: BreadcrumbItem[]
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ items, component = 'span' }) => {
  return (
    <Box sx={{ display: 'inline-flex' }}>
      {items
        .map(({ text, component: itemComponent, props }, i, arr) => (
          <Typography
            key={text}
            {...props}
            component={itemComponent || component}
            sx={{
              color: i === arr.length - 1 ? '#ffffff' : '#8E9197',
              fontSize: '12px',
              fontWeight: i === arr.length - 1 ? 600 : 500,
              textDecoration: 'none',
              ...props?.sx,
            }}
          >
            {text}
          </Typography>
        ))
        .flatMap((item, i, arr) => {
          return i === arr.length - 1
            ? item
            : [
                item,
                <Typography
                  key={`delimiter${i}`}
                  component="span"
                  sx={{
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    color: '#8E9197',
                    fontSize: '12px',
                    fontWeight: 500,
                    ...items[i]?.props?.sx,
                  }}
                >
                  /
                </Typography>,
              ]
        })}
    </Box>
  )
}

export default Breadcrumbs
