import withTheme from '../../utils/withTheme'
import ContextMenuUnstyled, { ContextMenuProps } from './ContextMenu'

const ContextMenu = withTheme<ContextMenuProps>(
  ContextMenuUnstyled,
  'ContextMenu'
)

export type { ContextMenuProps } from './ContextMenu'
export default ContextMenu
