import Box from '@mui/material/Box'
import { FC, ReactNode } from 'react'
import spaces, { SpaceType } from '../../utils/spaces'

enum ScoreBg {
  positive = 'success.main',
  neutral = 'neutral.main',
  negative = 'error.main',
}
enum ScoreColor {
  positive = '#161A23',
  neutral = 'neutral.contrastText',
  negative = '#161A23',
}
enum ScoreBorderRadius {
  'small' = '2px',
  'big' = '8px',
}
enum ScoreFontSize {
  'small' = '12px',
  'big' = '32px',
}
enum ScoreSize {
  'small' = '24px',
  'big' = '72px',
}

export type ScoreProps = {
  size?: keyof typeof ScoreSize
  level?: keyof typeof ScoreBg
  text: ReactNode
  m?: SpaceType
}

const Score: FC<ScoreProps> = ({
  text,
  level = 'neutral',
  size = 'small',
  m = '0',
  ...rest
}) => {
  return (
    <Box
      {...rest}
      component="span"
      sx={{
        ...spaces(m, 'margin'),
        typography: 'body2',
        minWidth: ScoreSize[size],
        minHeight: ScoreSize[size],
        fontSize: ScoreFontSize[size],
        borderRadius: ScoreBorderRadius[size],
        color: ScoreColor[level],
        backgroundColor: ScoreBg[level],
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {text}
    </Box>
  )
}

export default Score
