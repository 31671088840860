import { Project } from '@tvi/types/project'
import { IdType, SuccessResponse } from '../..'
import TviApiService from '../../TviApiService'

export type GetProjectsResponse = Project[]
export type GetProjectResponse = Project

export interface ProjectEndpoints {
  // When using the query param expanded=videos,
  // the project.videos property will be a list of detailed video objects
  // otherwise by default project.videos will be a list of video ids
  getProjectsByBrandId({
    brandId,
    expanded,
  }: {
    brandId: number
    expanded?: boolean
  }): Promise<GetProjectsResponse>
  getProject(args: IdType): Promise<GetProjectResponse>
  deleteProject(args: IdType): Promise<SuccessResponse>
}

export default class ProjectService
  extends TviApiService
  implements ProjectEndpoints
{
  async getProjectsByBrandId({
    brandId,
    expanded = false,
  }: {
    brandId: number
    expanded?: boolean
  }): Promise<GetProjectsResponse> {
    return this.request({
      method: 'GET',
      url: this.url(
        `/brands/${brandId}/projects${expanded ? '?videos=expanded' : ''}`
      ),
    }).then(this.takeOk)
  }

  async getProject({ id }: IdType): Promise<GetProjectResponse> {
    return this.request({
      method: 'GET',
      url: this.url(`/projects/${id}`),
    }).then(this.takeOk)
  }

  async deleteProject({ id }: IdType): Promise<SuccessResponse> {
    return this.request({
      method: 'DELETE',
      url: this.url(`/projects/${id}`),
    }).then(this.takeOk)
  }
}
