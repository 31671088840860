import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material/styles/createTheme'

export const AudioTimeLineItemContainer = styled('div')(
  ({ theme }: { theme: Theme }) => ({
    '&.active': {
      // opacity: 0.9,
      zIndex: 1,
      transition: 'all 0.5s ease',
    },
    '&.active:before': {
      position: 'absolute',
      top: 2,
      left: 2,
      width: 'calc(100% - 4px)',
      height: 'calc(100% - 4px)',
      opacity: 1,
      borderRadius: 4,
      outline: `2px solid white`,
      transition: 'outline 0.3s ease',
      pointerEvents: 'none',
      zIndex: 2,
      content: '""',
    },
    '&.active.no-drop': {
      opacity: 0.7,
    },
    '&.active.no-drop:before': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
    '&.trimming .audio-trim:before': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: `calc(100% - 4px)`,
      height: `calc(100% - 4px)`,
      opacity: 0.6,
      borderRadius: 5,
      border: `2px solid white`,
      pointerEvents: 'none',
      content: '""',
    },
  })
)
