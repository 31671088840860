import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import { PopperProps } from '@mui/material/Popper'
import { SxProps, SystemCssProperties } from '@mui/system'
import { Children, FC, useState } from 'react'
import { TVITheme } from '../../theme'
import { Arrow, PopperWithArrow } from './ContextMenu.styled'

export type ContextMenuProps = Omit<PopperProps, 'open'> & {
  onClose(): void
  anchorEl?: HTMLElement | null
  isCustomList?: boolean
  open?: boolean
  arrow?: boolean
  zIndex?: number
  sx?: SxProps
}

const ContextMenu: FC<ContextMenuProps> = ({
  anchorEl,
  onClose,
  isCustomList = false,
  open = false,
  arrow = true,
  sx: PaperSxProps = {
    background: 'white',
  },
  zIndex = 1,
  children,
  ...rest
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null)
  return (
    <PopperWithArrow
      arrow={arrow}
      open={open}
      anchorEl={anchorEl}
      zIndex={zIndex}
      bg={
        typeof PaperSxProps === 'function'
          ? (theme: TVITheme) =>
              (PaperSxProps(theme) as SystemCssProperties)?.background ||
              (PaperSxProps(theme) as SystemCssProperties)?.backgroundColor
          : (PaperSxProps as SystemCssProperties)?.background ||
            (PaperSxProps as SystemCssProperties)?.backgroundColor
      }
      modifiers={[
        {
          name: 'arrow',
          enabled: arrow,
          options: {
            element: arrowRef,
          },
        },
      ]}
      {...rest}
    >
      {arrow ? (
        <Arrow
          ref={setArrowRef as (instance: HTMLDivElement | null) => void}
          className="MuiPopper-arrow"
        />
      ) : null}
      <ClickAwayListener onClickAway={onClose}>
        <Paper sx={PaperSxProps}>
          {isCustomList && children}
          {!isCustomList && (
            <MenuList dense autoFocusItem={open}>
              {Children.map(children, (item, index) => (
                <MenuItem dense key={index}>
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          )}
        </Paper>
      </ClickAwayListener>
    </PopperWithArrow>
  )
}

export default ContextMenu
