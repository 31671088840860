import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

const TVILineHorizontalIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <line
        x1="5"
        x2="19"
        y1="12.5"
        y2="12.5"
        stroke="currentColor"
        strokeWidth="3"
      />
    </SvgIcon>
  )
}

export default TVILineHorizontalIcon
