export type TviServices = Record<eviApiVersionNames, AnyClass<any>[]>
export type eviApiVersionNames = string | 'v1'
export type eviApiVersions = {
  [key in eviApiVersionNames]?: string
}

interface AnyClass<T> {
  new (baseUrl: string, requestInstance: any): T
}

export type eviApiConfig = {
  versions: eviApiVersions
  requestInstance: any
}

/**
 * Facade for TVI API Services
 **/

class eviApi {
  static instance<Endpoints>() {
    /**
     * we have to instantiate the class using this method
     * in order to explicit define methods from api services on facade
     */
    return new eviApi() as eviApi & Endpoints
  }

  private baseURLs: eviApiVersions = {}
  private requestInstance: any
  private services: TviServices = {}

  configure(config: eviApiConfig) {
    this.baseURLs = { ...config.versions }
    this.requestInstance = config.requestInstance
    this.configureServices()
  }

  registerServices(apiVersion: eviApiVersionNames, services: AnyClass<any>[]) {
    this.services[apiVersion] = this.services[apiVersion] || []
    this.services[apiVersion] = services
    if (!this.requestInstance) return
    this.configureServices()
  }

  private configureServices() {
    Object.entries(this.services).forEach(([apiVersion, services]) => {
      services.forEach((Service) => {
        const baseURL = this.baseURLs[apiVersion]!
        const service = new Service(baseURL, this.requestInstance)

        if (!this.hasOwnProperty(apiVersion)) {
          Object.defineProperty(this, apiVersion, {
            value: {},
          })
        }

        Object.getOwnPropertyNames(Object.getPrototypeOf(service)).forEach(
          (endpoint) => {
            if (endpoint === 'constructor') return
            // @ts-ignore
            const namespace = this[apiVersion] ?? {}
            const method = service[endpoint]
            if (typeof method !== 'function') return
            Object.defineProperty(namespace, endpoint, {
              value: service[endpoint].bind(service),
              configurable: true,
            })
          }
        )
      })
    })
  }
}

export default eviApi
