import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import withTheme from '../../utils/withTheme'
import ButtonMenuUnstyled, { ButtonMenuProps } from './ButtonMenu'

const Button = withTheme<LoadingButtonProps>(LoadingButton, 'Button')

export const ButtonMenu = withTheme<ButtonMenuProps>(
  ButtonMenuUnstyled,
  'ButtonMenu'
)
export type { LoadingButtonProps as ButtonProps } from '@mui/lab/LoadingButton'
export type { ButtonMenuProps } from './ButtonMenu'
export default Button
