import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core'
import { DropAnimation } from '@dnd-kit/core/dist/components/DragOverlay/hooks'
import {
  ComponentType,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  useEffect,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import WrapInto from '../../utils/WrapInto'
import { FilmstripTrack as FilmstripTrackDefault } from './Filmstrip.styled'
import { OVERLAY_DEBOUNCE } from './FilmstripGroup'
import { DropEvent, useFilmstripDnD } from './hooks/useFilmstripDnD'
import {
  FilmstripScaleOptions,
  FilmstripScaleProvider,
  useFilmstripScale,
} from './hooks/useFilmstripScale'
import {
  FilmstripItemStateProvider,
  SelectedId,
  useFilmstripItemState,
} from './hooks/useFilmstripState'

export type FilmstripProps = FilmstripScaleOptions & {
  isScrollable?: boolean
  isInGroup?: boolean
  dropAnimation?: DropAnimation
  scaleValue?: number
  scalable?: boolean
  scaleMaxValue?: number
  scaleMinValue?: number
  onDrop?(e: DropEvent): void
  onSelect?(e: SelectedId): void
  FilmstripTrack?: ComponentType
}

const Filmstrip: ForwardRefExoticComponent<FilmstripProps> = forwardRef(
  (
    {
      onDrop,
      onSelect,
      isInGroup,
      dropAnimation,
      isScrollable,
      scalable = false,
      scaleValue = 100,
      scaleMaxValue,
      scaleMinValue,
      children,
      FilmstripTrack = FilmstripTrackDefault,
      ...rest
    },
    trackRef: ForwardedRef<HTMLDivElement>
  ) => {
    const [isShowOverlay, setIsShowOverlay] = useState<boolean>(false)
    useEffect(() => setScale(scaleValue), [scaleValue])
    const state = useFilmstripItemState()
    const { scaleFn, setScale } = useFilmstripScale({
      scalable,
      scaleMinValue,
      scaleMaxValue,
    })
    const { dragItem, handleDragEnd, handleDragStart } = useFilmstripDnD({
      onDrop,
    })

    useEffect(() => {
      if (state.selected === null) return
      onSelect?.(state.selected)
    }, [state.selected])

    useEffect(() => {
      /*
       * DragOverlay overlaps the content and we can't handle mouseup.
       * This debounse is a workaround.
       * */
      const id = setTimeout(
        () => setIsShowOverlay(Boolean(dragItem)),
        OVERLAY_DEBOUNCE
      )
      return () => clearTimeout(id)
    }, [dragItem])

    return (
      <FilmstripTrack
        {...rest}
        ref={trackRef}
        isScrollable={isScrollable}
        aria-label="filmstrip"
      >
        <WrapInto isWrap={!isInGroup}>
          <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}
          >
            {isShowOverlay &&
              !isInGroup &&
              createPortal(
                <DragOverlay dropAnimation={dropAnimation}>
                  {dragItem}
                </DragOverlay>,
                document.body
              )}
            <FilmstripScaleProvider value={scaleFn}>
              <FilmstripItemStateProvider value={state}>
                {children}
              </FilmstripItemStateProvider>
            </FilmstripScaleProvider>
          </DndContext>
        </WrapInto>
      </FilmstripTrack>
    )
  }
)

export default Filmstrip
