import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

const TVICircleCheckIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M 12 4 C 7.582 4 4 7.582 4 12 C 4 16.418 7.582 20 12 20 C 16.418 20 20 16.418 20 12 C 20 7.582 16.418 4 12 4 Z M 11.167 15.528 L 8.167 12.619 L 9.405 11.38 L 11.167 13.051 L 14.929 9.195 L 16.167 10.433 L 11.167 15.528 Z" />
    </SvgIcon>
  )
}

export default TVICircleCheckIcon
