import {
  closestCenter,
  CollisionDetection,
  DndContext,
  DragOverlay,
} from '@dnd-kit/core'
import { DropAnimation } from '@dnd-kit/core/dist/components/DragOverlay/hooks'
import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { DropEvent, useFilmstripDnD } from './hooks/useFilmstripDnD'

export const OVERLAY_DEBOUNCE = 20

export type FilmstripGroupProps = {
  dropAnimation?: DropAnimation | null
  onDrop?(e: DropEvent): void
  collisionDetection?: CollisionDetection
}

const FilmstripGroup: FC<FilmstripGroupProps> = ({
  onDrop,
  dropAnimation,
  children,
  collisionDetection = closestCenter,
}) => {
  const [isShowOverlay, setIsShowOverlay] = useState<boolean>(false)
  const { dragItem, handleDragEnd, handleDragStart } = useFilmstripDnD({
    onDrop,
  })

  useEffect(() => {
    /*
     * DragOverlay overlaps the content and we can't handle mouseup.
     * This debounse is a workaround.
     * */
    const id = setTimeout(
      () => setIsShowOverlay(Boolean(dragItem)),
      OVERLAY_DEBOUNCE
    )
    return () => clearTimeout(id)
  }, [dragItem])

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      collisionDetection={collisionDetection}
    >
      {children}
      {isShowOverlay &&
        createPortal(
          <DragOverlay dropAnimation={dropAnimation}>{dragItem}</DragOverlay>,
          document.body
        )}
    </DndContext>
  )
}

export default FilmstripGroup
