import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles/createTheme'

const DROP_ZONE_ACTIVE = 'rgba(255, 255, 255, 0.3)'

export const FilmstripItemWrapper = styled.div`
  display: flex;
`

export const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const VerticalSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HorizontalSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
`

export const DroppableWrapper = styled.div(
  {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  (props: { padding: any }) => ({
    padding:
      typeof props.padding === 'number' || typeof props.padding === 'string'
        ? props.padding
        : undefined,
  })
)

export const DroppableZone = styled.div(
  {
    height: '100%',
  },
  (props: { width?: string | number; isActive?: boolean }) => ({
    width: typeof props.width === 'number' ? props.width + 'px' : props.width,
    background: props.isActive ? DROP_ZONE_ACTIVE : undefined,
  })
)

export const DraggableComp = styled.div(
  (props: {
    theme?: Theme
    width: number
    height: number
    isShowPlaceholder?: boolean
    defaultPlaceholder?: boolean
  }) => ({
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    outline: 'unset',
    width: props.width,
    height: props.height,
    '&:before': {
      content: '""',
      display: props.isShowPlaceholder ? 'block' : 'none',
      width: props.width,
      height: props.height,
      background: props.theme!.palette.background.paper,
      zIndex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
    },
  })
)

export const HitZone = styled.div(
  {
    flexShrink: 0,
    width: '100%',
    height: '100%',
    position: 'relative',
    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      start: 0,
    },
  },
  (props: { isActive?: boolean }) => ({
    '&:before': {
      display: props.isActive ? 'block' : 'none',
      background: props.isActive ? DROP_ZONE_ACTIVE : undefined,
    },
  })
)

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
