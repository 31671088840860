import { useDraggable, useDroppable } from '@dnd-kit/core'
import React, {
  FC,
  forwardRef,
  LegacyRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { theme } from '../../theme'
import BorderFrame from '../../utils/BorderFrame'
import WrapInto from '../../utils/WrapInto'
import { OVERLAY_DEBOUNCE } from './FilmstripGroup'
import {
  DraggableComp,
  DroppableWrapper,
  DroppableZone,
  FilmstripItemWrapper,
  HitZone,
  HorizontalSection,
  HorizontalWrapper,
  Img,
  VerticalSection,
} from './FilmstripItem.styled'
import { filmstripScaleCtx, FilmstripScaleCtx } from './hooks/useFilmstripScale'
import {
  filmstripItemStateCtx,
  FilmstripItemStateCtx,
} from './hooks/useFilmstripState'

const selectedBorderSize = (width: number, minSize = 1, maxSize = 4): number =>
  // scale border with min and max size (min=1 and max=4) (0.04 - 4 percent of width)
  Math.max(minSize, Math.min(maxSize, width * 0.04))

export type FilmstripItemProps = {
  id: string | number
  image?: string
  width: number
  height: number
  isDraggable?: boolean
  isDroppable?: boolean
  isLeftDrop?: boolean
  isHitDrop?: boolean
  isRightDrop?: boolean
  leftSection?: any
  topSection?: any
  bottomSection?: any
  rightSection?: any
  dropZoneSize?: string | number
  data?: { [key: string]: any }
  placeholder?: any
  defaultPlaceholder?: boolean
  selectionColor?: string
  onActive?(isActive: boolean): void
  onMove?(isMoving: boolean): void
}
const FilmstripItem: FC<FilmstripItemProps> = forwardRef(
  (
    {
      onActive,
      onMove,
      children,
      id,
      image,
      width,
      height,
      isDraggable,
      isDroppable,
      isLeftDrop = true,
      isHitDrop = true,
      isRightDrop = true,
      leftSection,
      topSection,
      bottomSection,
      rightSection,
      dropZoneSize,
      data = {},
      defaultPlaceholder = true,
      placeholder,
      selectionColor = theme.palette.primary.main,
      ...rest
    },
    ref
  ) => {
    const DROP_ZONE_PERCENT = 0.2
    const [isShowPlaceholder, setIsShowPlaceholder] = useState<boolean>(false)
    const filmstripState = useContext<FilmstripItemStateCtx>(
      filmstripItemStateCtx
    )

    const scale = useContext<FilmstripScaleCtx>(filmstripScaleCtx)
    const content = children || <Img key="img" src={image} />
    const itemData = {
      id,
      data,
      content,
    }
    const {
      attributes,
      listeners,
      setNodeRef: setDraggableRef,
      isDragging,
    } = useDraggable({
      id: ['FilmstripItem-draggable', id].join('-'),
      disabled: !isDraggable,
      data: itemData,
    })
    const dropBefore = useDroppable({
      id: ['FilmstripItem-droppable-before', id].join('-'),
      disabled: isDroppable ? isDroppable && isDragging : true,
      data: {
        ...itemData,
        zone: 'before',
      },
    })
    const dropAfter = useDroppable({
      id: ['FilmstripItem-droppable-after', id].join('-'),
      disabled: isDroppable ? isDroppable && isDragging : true,
      data: {
        ...itemData,
        zone: 'after',
      },
    })
    const dropHit = useDroppable({
      id: ['FilmstripItem-droppable-hit', id].join('-'),
      disabled: isDroppable ? isDroppable && isDragging : true,
      data: {
        ...itemData,
        zone: 'hit',
      },
    })

    const handleFocus = useCallback(() => filmstripState.setSelected(id), [id])
    const handleClick = useCallback(() => {
      filmstripState.setSelected(id)
    }, [id])
    const handleHover = useCallback(
      (e) => {
        switch (e.type) {
          case 'mouseenter':
            filmstripState.setHovered(id)
            break
          case 'mouseleave':
            filmstripState.setHovered(null)
            break
        }
      },
      [id]
    )

    useEffect(() => {
      onActive?.(
        Boolean(
          isDragging && dropBefore.isOver && dropAfter.isOver && dropHit.isOver
        )
      )
    }, [isDragging, dropBefore.isOver, dropAfter.isOver, dropHit.isOver])

    useEffect(() => {
      onMove?.(isDragging)
    }, [isDragging])

    useEffect(() => {
      /*
       * DragOverlay overlaps the content and we can't handle mouseup.
       * This debounse is a workaround.
       * */
      const id = setTimeout(
        () => setIsShowPlaceholder(isDragging),
        OVERLAY_DEBOUNCE
      )
      return () => clearTimeout(id)
    }, [isDragging])

    return (
      <FilmstripItemWrapper
        {...rest}
        ref={ref as LegacyRef<HTMLDivElement>}
        id={`FilmstripItem-${id}`}
      >
        <VerticalSection aria-label="component-section-left">
          {leftSection}
        </VerticalSection>
        <HorizontalWrapper>
          <HorizontalSection aria-label="component-section-top">
            {topSection}
          </HorizontalSection>
          <DroppableWrapper padding={!isDroppable && dropZoneSize}>
            {Boolean(isDroppable && isLeftDrop) && (
              <DroppableZone
                isActive={dropBefore.isOver}
                width={dropZoneSize ?? scale(width) * DROP_ZONE_PERCENT}
                ref={dropBefore.setNodeRef}
                aria-label="drop-zone-left"
              />
            )}
            <DraggableComp
              width={scale(width)}
              height={scale(height)}
              isShowPlaceholder={
                isDragging &&
                isShowPlaceholder &&
                defaultPlaceholder &&
                !placeholder
              }
              onFocus={handleFocus}
              onClick={handleClick}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              ref={setDraggableRef}
              {...listeners}
              {...attributes}
              aria-label="draggable-area"
            >
              <BorderFrame
                aria-label="BorderFrame"
                style={{
                  width: '100%',
                  height: '100%',
                }}
                width={
                  filmstripState.selected === id
                    ? selectedBorderSize(scale(width))
                    : 0
                }
                color={
                  filmstripState.selected === id
                    ? selectionColor
                    : 'transparent'
                }
              >
                <WrapInto isWrap={Boolean(isDroppable && isHitDrop)}>
                  <HitZone
                    aria-label="drop-zone-hit"
                    isActive={dropHit.isOver}
                    ref={dropHit.setNodeRef}
                  >
                    {isDragging && isShowPlaceholder && placeholder
                      ? placeholder
                      : content}
                  </HitZone>
                </WrapInto>
              </BorderFrame>
            </DraggableComp>
            {Boolean(isDroppable && isRightDrop) && (
              <DroppableZone
                isActive={dropAfter.isOver}
                width={dropZoneSize ?? scale(width) * DROP_ZONE_PERCENT}
                ref={dropAfter.setNodeRef}
                aria-label="drop-zone-right"
              />
            )}
          </DroppableWrapper>
          <HorizontalSection aria-label="component-section-bottom">
            {bottomSection}
          </HorizontalSection>
        </HorizontalWrapper>
        <VerticalSection aria-label="component-section-right">
          {rightSection}
        </VerticalSection>
      </FilmstripItemWrapper>
    )
  }
)

export default FilmstripItem
