import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { HTMLAttributes } from 'react'

export const FilmstripTrack = styled(Box)(
  {
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
  },
  (props: { isScrollable?: boolean } & HTMLAttributes<HTMLDivElement>) => ({
    overflow: props.isScrollable ? 'auto' : undefined,
  })
)
