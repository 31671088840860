import withTheme from '../../utils/withTheme'
import BreadcrumbsUnstyled, { BreadcrumbsProps } from './Breadcrumbs'

const Breadcrumbs = withTheme<BreadcrumbsProps>(
  BreadcrumbsUnstyled,
  'Breadcrumbs'
)

export type { BreadcrumbItem, BreadcrumbsProps } from './Breadcrumbs'
export default Breadcrumbs
