import type {} from '@mui/lab/themeAugmentation'
import { createTheme } from '@mui/material'
import { ThemeOptions } from '@mui/material/styles/createTheme'
import { pxToRem } from './utils/html-units'

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }
  interface Palette {
    white: Palette['primary']
  }
  interface PaletteOptions {
    white: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
  interface ButtonPropsVariantOverrides {
    icon: true
  }
}

export const themeOptions: ThemeOptions = {
  spacing: pxToRem,
  palette: {
    mode: 'dark',
    tonalOffset: 0.24,
    primary: {
      main: '#4DB0C2',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#D46148',
    },
    error: {
      main: '#D44848',
    },
    success: {
      main: '#00FFB2',
    },
    neutral: {
      main: '#343944',
      contrastText: '#ffffff',
    },
    white: {
      /**
       * This is a utility color that should be used as a name of the color
       * for the "color" attribute.
       * You shouldn't use it as a variant if the theme.
       */
      main: '#ffffff',
      contrastText: '#ffffff',
    },
    background: {
      default: '#161A23',
      paper: '#292E39',
    },
    action: {
      hover: 'rgba(0,0,0,0.04)',
      hoverOpacity: 0.04,
    },
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          display: 'inline-flex',
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        root: {
          select: {
            borderRadius: '10px',
            background: 'rgba(255, 255, 255, 0.05)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          marginLeft: pxToRem('24px'),
          marginBottom: pxToRem('13px'),
          position: 'static',
          transform: 'none',
          fontWeight: 600,
          color: '#ffffff',
          '&.Mui-focused': {
            color: '#ffffff',
          },
          '& + .MuiInputBase-formControl .MuiInputBase-input.MuiOutlinedInput-input::-webkit-input-placeholder':
            {
              opacity: '1!important',
              transition: 'unset !important',
            },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'rgba(255, 255, 255, 0.05)',
          color: '#8E9197',
          borderRadius: '24px',
          '&.Mui-focused': {
            color: '#ffffff',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: pxToRem('1px'),
          },
        },
        input: {
          paddingTop: pxToRem('12.5px'),
          paddingRight: pxToRem('24px'),
          paddingBottom: pxToRem('12.5px'),
          paddingLeft: pxToRem('24px'),
          fontWeight: 600,
          '&.MuiInputBase-inputAdornedStart': {
            paddingLeft: pxToRem('10px'),
          },
          '&.MuiInputBase-inputAdornedEnd': {
            paddingRight: pxToRem('10px'),
          },
        },
        notchedOutline: {
          borderWidth: pxToRem('1px'),
          borderColor: '#343944',
          '& legend': {
            width: '0',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'unset',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-disabled': {
            color: '#343944',
          },
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
        colorPrimary: {
          '&:hover': {
            filter: 'brightness(0.6)',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: pxToRem('2px'),
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: '24px',
        },
        contained: {
          '&:disabled': {
            color: '#ffffff',
            background: '#343944',
          },
        },
        containedSizeMedium: {
          paddingTop: pxToRem('10px'),
          paddingRight: pxToRem('30px'),
          paddingBottom: pxToRem('10px'),
          paddingLeft: pxToRem('30px'),
        },
        text: {
          '&:hover': {
            background: 'unset',
            filter: 'brightness(0.6)',
          },
          '&:disabled': {
            color: '#8E9197',
          },
        },
        sizeSmall: {
          paddingTop: pxToRem('2px'),
          paddingRight: pxToRem('10px'),
          paddingBottom: pxToRem('1px'),
          paddingLeft: pxToRem('10px'),
          fontSize: pxToRem('12px'),
          borderRadius: pxToRem('2px'),
        },
        outlinedSizeSmall: {
          paddingTop: pxToRem('1px'),
          paddingRight: pxToRem('9px'),
          paddingBottom: '0',
          paddingLeft: pxToRem('9px'),
        },
      },
      variants: [
        {
          props: { variant: 'icon' },
          style: {
            padding: pxToRem('12px'),
            background: '#22252E',
            border: '1px solid #343944',
            boxSizing: 'border-box',
            borderRadius: pxToRem('2px'),
            textTransform: 'none',
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: pxToRem('24px'),
          height: pxToRem('24px'),
          margin: pxToRem('10px'),
          flexShrink: 0,
          background: '#292E39',
          border: '1px solid #343944',
          boxSizing: 'border-box',
          borderRadius: pxToRem('2px'),
          '&.Mui-checked': {
            background: '#4DB0C2',
            borderColor: '#4DB0C2',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#ffffff',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          height: pxToRem('12px'),
          background: '#292E39',
          border: '1px solid #343944',
          boxSizing: 'border-box',
          borderRadius: pxToRem('2px'),
        },
        thumb: {
          borderRadius: pxToRem('2px'),
          '&:hover': {
            boxShadow: 'none',
          },
          '&.Mui-active': {
            boxShadow: 'none',
          },
          '&.Mui-focusVisible': {
            boxShadow: 'none',
          },
        },
        mark: {
          display: 'none',
        },
        markLabel: {
          top: pxToRem('24px'),
          color: '#8E9197',
          fontSize: pxToRem('8px'),
          fontWeight: 500,
          '&:zoom': {
            top: pxToRem('24px'),
            color: '#8E9197',
            fontSize: pxToRem('8px'),
            fontWeight: 500,
          },
        },
        markLabelActive: {
          color: '#8E9197',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          background: 'transparent',
          boxSizing: 'border-box',
          borderBottom: '1px solid #000000',
        },
        vertical: {
          borderBottom: 'none',
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        },
        indicator: {
          height: pxToRem('4px'),
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#8E9197',
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          background: '#161A23',
          '&::before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #343944',
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        content: {
          marginTop: pxToRem('12px'),
          marginRight: '0',
          marginBottom: pxToRem('12px'),
          marginLeft: '0',
          '&.Mui-expanded': {
            marginTop: pxToRem('12px'),
            marginRight: '0',
            marginBottom: pxToRem('12px'),
            marginLeft: '0',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        dense: {
          paddingTop: pxToRem('4px'),
          paddingBottom: pxToRem('4px'),
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        dense: {
          paddingTop: pxToRem('4px'),
          paddingBottom: pxToRem('4px'),
          paddingLeft: pxToRem('8px'),
          paddingRight: pxToRem('8px'),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: '#343944',
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: 'Work Sans',
    fontSize: 16,
    allVariants: {
      color: '#ffffff',
    },
    h1: {
      fontSize: '4.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h4: {},
    h5: {},
    h6: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '0.75rem',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 300,
      color: '#8E9197',
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: '#8E9197',
    },
    button: {
      fontSize: '1rem',
      fontWeight: 600,
      textTransform: 'none',
    },
  },
}

export const theme = createTheme(themeOptions)
export type TVITheme = typeof theme
