import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FC } from 'react'
import { pxToRem } from '../../utils/html-units'

export type TextBlock1Props = {
  title: string
} & BoxProps

const TextBlock1: FC<TextBlock1Props> = ({ title, children, ...rest }) => {
  return (
    <Box
      {...rest}
      sx={{
        textAlign: 'center',
        ...rest.sx,
      }}
    >
      <Typography variant="h3">{title}</Typography>
      <Typography
        sx={{
          marginTop: pxToRem(28),
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default TextBlock1
