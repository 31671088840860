import { FC } from 'react'
import { ZoomSlider } from '../../index'

export const filmstripScaleMarks = (min: number, max: number) => {
  const scaleMiddle = max - (max - min) / 2
  return [
    {
      value: min,
      label: '0%',
    },
    {
      value: scaleMiddle,
      label: 'Zoom',
    },
    {
      value: max,
      label: '100%',
    },
  ]
}

export type FilmstripScaleProps = {
  min?: number
  max?: number
  scaleTo?: number
  value?: number
  defaultValue?: number
  disabled?: boolean
  onChange(value: number): void
}

const FilmstripScale: FC<FilmstripScaleProps> = ({
  min,
  max,
  scaleTo = 2,
  value,
  defaultValue,
  disabled,
  onChange,
}) => {
  const SLIDER_STEP_NUM = 20
  const ONE_HUNDRED_PERCENT = 100
  const scaleMin = min ?? 0
  const scaleMax = (max ?? 100) * scaleTo

  return (
    <ZoomSlider
      defaultValue={defaultValue ?? ONE_HUNDRED_PERCENT}
      value={value}
      min={scaleMin}
      max={scaleMax}
      step={scaleMax / SLIDER_STEP_NUM}
      marks={filmstripScaleMarks(scaleMin, scaleMax)}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export default FilmstripScale
