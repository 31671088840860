import { ThemeProvider } from '@mui/material'
import {
  ComponentType,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react'
import { theme } from '../theme'

/**
 * withTheme is a HOC that wraps component with ThemeProvider
 * */

type WithTheme = <P = {}>(
  Comp: ComponentType<P>,
  displayName: string
) => ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<any>>

const withTheme: WithTheme = function <P>(
  Comp: ComponentType<P>,
  displayName: string
): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<any>> {
  const StyledComp = forwardRef<any, P>((props, ref) => (
    <ThemeProvider theme={theme}>
      <Comp {...props} ref={ref} />
    </ThemeProvider>
  ))
  StyledComp.displayName = displayName.toString().replace(/^(TVI)?/, 'TVI')
  return StyledComp
}

export default withTheme
