import { Modifier } from '@dnd-kit/core'
import {
  createSnapModifier,
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import { SxProps } from '@mui/system'
import clsx from 'clsx'
import {
  ComponentType,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import WrapInto from '../../utils/WrapInto'
import {
  AudioTimeLineContainer,
  AudioTimeLineTrack,
} from './AudioTimeline.styled'
import { getNextPoint, scaleTimeToPx } from './audioTimeline.utils'
import { audioTimelineDragCtx } from './AudioTimelineCtx'
import AudioTimelineItem from './AudioTimelineItem'
import AudioTrim from './AudioTrim'
import Clip from './Clip'

export type TimelineItem = {
  id: string
  startPoint: number
  endPoint: number
  active?: 1 | 0
  nextStartPoint?: number
  nextEndPoint?: number
  [key: string]: any
}

export type TimelineEvent = TimelineItem & {
  nextStartPoint: number
  nextEndPoint: number
}

export type AudioTimelineProps = {
  items: TimelineItem[]
  timelineMs: number
  width: number
  height?: number
  isTrim?: boolean
  isMove?: boolean
  timelineScaleable?: boolean
  onTrim?(e: TimelineEvent): void
  onMove?(e: TimelineEvent[]): void
  onTimelineChange?(size?: number): void
  ClipComponent?: ComponentType
  sx?: SxProps
}

const AudioTimeline: FC<AudioTimelineProps> = ({
  items,
  timelineMs,
  width,
  onTrim,
  onMove,
  height = 40,
  isMove = false,
  isTrim = false,
  timelineScaleable = true,
  onTimelineChange = () => void 0,
  ClipComponent = Clip,
  sx,
}) => {
  const setDndCtxProps = useContext(audioTimelineDragCtx)
  const [transformedItems, setTransformedItems] = useState<any[]>(items)
  const deltaMapRef = useRef<Record<string, number>>({})
  const [activeId, setActiveId] = useState<string | null>(null)
  const [activeItem, setActiveItem] = useState<any>(null)
  const [intersectId, setIntersectId] = useState<string | null>(null)
  const [isTrimming, setIsTrimming] = useState<boolean>(false)
  const [trimItem, setTrimItem] = useState<any>(null)
  const scale = useMemo(
    () => scaleTimeToPx(timelineMs, width),
    [timelineMs, width]
  )
  const setDelta = ({ id, delta }: { id: string; delta: number }) => {
    deltaMapRef.current[id] = delta
  }

  const handleDragStart = (clip: any) => {
    const item = transformedItems.find((item) => item.id === clip.active.id)
    setActiveItem(item)
  }

  const OFFSET = 0.0001

  const sortInc = (a: TimelineItem, b: TimelineItem) =>
    a.startPoint - b.startPoint
  const sortDec = (a: TimelineItem, b: TimelineItem) =>
    b.startPoint - a.startPoint
  const sortActive = (a: TimelineItem, b: TimelineItem) =>
    (b.active ?? 0) - (a.active ?? 0)

  const intersect = (target: TimelineItem, compare: TimelineItem) => {
    if (!target || !compare) return false
    if (target.id === compare.id) return false
    if (
      (target.endPoint > compare.startPoint &&
        target.endPoint < compare.endPoint) ||
      (target.startPoint < compare.endPoint &&
        target.startPoint > compare.startPoint) ||
      (target.startPoint < compare.startPoint &&
        target.endPoint > compare.endPoint)
    ) {
      return true
    }
    return false
  }

  const intersectSide = (target: TimelineItem, compare: TimelineItem) => {
    return compare.endPoint >= target.startPoint &&
      compare.startPoint > target.startPoint
      ? 1
      : 0
  }

  const move = useMemo(
    () => (active: TimelineItem, intersect: TimelineItem) => {
      const side = intersectSide(active, intersect)
      const width = intersect.endPoint - intersect.startPoint

      let startPoint = Math.max(
        0,
        side ? active.endPoint + OFFSET : active.startPoint - width - OFFSET
      )
      let endPoint = Math.min(
        timelineMs,
        side ? active.endPoint + width - OFFSET : active.startPoint - OFFSET
      )

      // allow timeline to grow
      if (timelineScaleable) {
        startPoint = side
          ? active.endPoint + OFFSET
          : active.startPoint - width - OFFSET
        endPoint = side
          ? active.endPoint + width - OFFSET
          : active.startPoint - OFFSET
      }

      return {
        ...intersect,
        startPoint,
        endPoint,
      }
    },
    [timelineMs, timelineScaleable]
  )

  const getById = (
    list: TimelineItem[],
    target: TimelineItem
  ): TimelineItem | undefined => {
    return list.find((item) => item.id === target.id)
  }

  // prettier-ignore
  const update = (data: TimelineEvent[]): TimelineEvent[] => {
    let dir: 1 | 0 = 1
    return data
      .sort(sortInc)
      .sort(sortActive)
      .reduce<any>((inner: TimelineItem[], item: TimelineItem) => {
        const select = getById(inner, item)
        if (!select) return inner
        return (
          inner
            // change compare direction depending on last intersect direction
            .sort(dir ? sortInc : sortDec)
            .map((innerItem: TimelineItem) => {
              const selectInner = getById(inner, innerItem) as TimelineItem
              if (selectInner.id === select.id) {
                return select
              }
              if (!intersect(select, selectInner)) {
                return selectInner
              }
              dir = intersectSide(select, selectInner)
              return move(select, selectInner)
            })
        )
      }, data)
  }

  const getItemPoint = useMemo(
    () => (item: TimelineItem) => {
      const deltas = deltaMapRef.current
      const delta = deltas[item.id] ?? 0
      const startPoint = getNextPoint(timelineMs, width, item.startPoint, delta)
      const endPoint = getNextPoint(timelineMs, width, item.endPoint, delta)
      return {
        startPoint,
        endPoint,
      }
    },
    [deltaMapRef.current, timelineMs, width]
  )

  const getIntersect = useMemo(
    () =>
      (activeItem: any, targetItem: any): boolean => {
        if (targetItem.id === activeItem.id) return false
        const deltas = deltaMapRef.current

        const activeStart = getNextPoint(
          timelineMs,
          width,
          activeItem.startPoint,
          deltas[activeItem.id] ?? 0
        )
        const activeEnd = getNextPoint(
          timelineMs,
          width,
          activeItem.endPoint,
          deltas[activeItem.id] ?? 0
        )
        const targetStart = getNextPoint(
          timelineMs,
          width,
          targetItem.startPoint,
          deltas[targetItem.id] ?? 0
        )
        const targetEnd = getNextPoint(
          timelineMs,
          width,
          targetItem.endPoint,
          deltas[targetItem.id] ?? 0
        )

        if (
          (activeEnd > targetStart && activeEnd < targetEnd) ||
          (activeStart < targetEnd && activeStart > targetStart) ||
          (activeStart < targetStart && activeEnd > targetEnd)
        ) {
          return true
        }
        return false
      },
    [timelineMs, deltaMapRef, width]
  )

  const getIntersectItems = useMemo(
    () => (activeItem: any) => {
      return transformedItems.filter((item) => {
        return getIntersect(activeItem, item)
      })
    },
    [transformedItems]
  )

  const handleDragEnd = useCallback(() => {
    if (isTrimming) return
    if (!activeItem) return
    const activeId = activeItem.id

    const updatedItems = update(
      transformedItems.map((item) => ({
        ...item,
        ...getItemPoint(item),
        active: item.id === activeId,
      }))
    ).map((item) => ({
      ...item,
      nextStartPoint: item.startPoint,
      nextEndPoint: item.endPoint,
    }))

    const maxTimeline = updatedItems.reduce(
      (max, item) => Math.max(max, item.endPoint),
      0
    )

    onTimelineChange(maxTimeline)
    onMove?.(updatedItems as TimelineEvent[])
  }, [transformedItems, activeItem, isTrimming])

  const handleDragMove = useCallback(() => {
    if (!activeItem) return setIntersectId(null)
    const intersects = getIntersectItems(activeItem)
    setIntersectId(intersects?.[0])
  }, [activeItem])

  const handleTrimStart = useCallback(
    (id: string) => {
      setIsTrimming(true)
      setTrimItem(items[Number(id) - 1])
    },
    [setIsTrimming, items]
  )

  const handleTrimEnd = useCallback(
    (trimEvent: TimelineEvent) => {
      setIsTrimming(false)
      setTrimItem(null)
      onTrim?.(trimEvent)
    },
    [setTrimItem, setIsTrimming, onTrim]
  )

  useEffect(() => {
    setTransformedItems(items)
  }, [items])

  useEffect(() => {
    const timelineItemIds = transformedItems.map((item) => item.id)
    const skipModifier: (md: Modifier) => Modifier = (md: Modifier) => (args) =>
      timelineItemIds.includes(args.active?.id) ? md(args) : args.transform
    setDndCtxProps({
      onDragStart: handleDragStart,
      onDragEnd: handleDragEnd,
      onDragMove: handleDragMove,
      modifiers: [
        ...(timelineScaleable ? [] : [skipModifier(restrictToParentElement)]),
        skipModifier(restrictToHorizontalAxis),
        skipModifier(createSnapModifier(width / timelineMs)),
      ],
    })
  }, [
    transformedItems,
    timelineScaleable,
    width,
    timelineMs,
    handleDragStart,
    handleDragEnd,
    handleDragMove,
  ])

  return (
    <AudioTimeLineContainer
      className="audio-timeline-container"
      height={height}
      width={width}
      sx={sx}
    >
      <AudioTimeLineTrack
        className="audio-timeline-track"
        height={height}
        width={width}
      >
        {transformedItems?.map((item: any) => {
          const startPointPx = scale.timeToPx(item.startPoint)
          const endPointPx = scale.timeToPx(item.endPoint)
          const isActive = activeId === item.id
          const trimItemActive = trimItem?.id === item.id

          return (
            <AudioTimelineItem
              key={item.id}
              id={item.id}
              disabled={isTrimming || !isMove}
              height={height}
              startPoint={startPointPx}
              endPoint={endPointPx}
              onTransform={setDelta}
              className={clsx(
                'audio-timeline-item',
                isActive && 'active',
                trimItemActive && isTrimming && 'trimming',
                isActive && intersectId && 'no-drop'
              )}
            >
              <WrapInto isWrap={isTrim}>
                <AudioTrim
                  id={item.id}
                  className="clip"
                  onTrimStart={handleTrimStart}
                  onTrimEnd={handleTrimEnd}
                  startPoint={item.startPoint}
                  endPoint={item.endPoint}
                  width={endPointPx - startPointPx}
                >
                  <ClipComponent
                    data={item}
                    isActive={activeId === item.id}
                    height={height}
                    width={endPointPx - startPointPx}
                    onMouseDown={() => setActiveId(item.id)}
                  />
                </AudioTrim>
              </WrapInto>
            </AudioTimelineItem>
          )
        })}
      </AudioTimeLineTrack>
    </AudioTimeLineContainer>
  )
}

export default AudioTimeline
