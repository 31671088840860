import { area, max, min, scaleLinear, select } from 'd3'
import { FC, SVGAttributes, useEffect, useRef } from 'react'
import { DrawWaveformOptions } from '../../utils/waveform'

export type AudioClipProps = {
  upperPoints: number[]
  lowerPoints: number[]
  width: number
  height: number
  padding?: number
} & Omit<DrawWaveformOptions, 'upperPoints' | 'lowerPoints'> &
  SVGAttributes<SVGSVGElement>

const AudioClip: FC<AudioClipProps> = ({
  upperPoints = [],
  lowerPoints = [],
  width,
  height,
  padding = 2,
  waveColor = '#147789',
  ...attrs
}) => {
  const svgRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    if (!svgRef.current) return
    const offsetX = height / 2
    const x = scaleLinear()
    const y = scaleLinear()

    x.domain([0, Math.max(lowerPoints.length, upperPoints.length)]).rangeRound([
      0,
      width,
    ])

    y.domain([min(lowerPoints) ?? 0, max(upperPoints) ?? 0]).rangeRound([
      offsetX - padding,
      -offsetX + padding,
    ])

    const scaleY = (amplitude: number, height: number): number => {
      const range = 256
      const offset = 128
      return height / 2 - ((amplitude + offset) * height) / range
    }

    const dArea = area<number>()
      .x((d, i) => x(i))
      .y0((d, i) => scaleY(lowerPoints[i], height))
      .y1((d) => scaleY(d, height))

    select(svgRef.current)
      .select('path')
      .datum(upperPoints)
      .attr('transform', () => `translate(0, ${offsetX})`)
      .attr('d', dArea)
  }, [upperPoints, lowerPoints, width, height, padding])

  return (
    <svg {...attrs} height={height} width={width} ref={svgRef} key="audio-clip">
      <path stroke={waveColor} fill={waveColor} />
    </svg>
  )
}

export default AudioClip
