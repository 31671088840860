import { scaleLinear } from 'd3'
import { TimelineItem } from './AudioTimeline'

export const pointProjection = (
  sourceLine: number,
  destinationLine: number,
  point: number
) => {
  const ONE_HUNDRED_PERCENT = 100
  return (
    (((point * ONE_HUNDRED_PERCENT) / sourceLine) * destinationLine) /
    ONE_HUNDRED_PERCENT
  )
}

export const scaleTimeToPx = (timelineMs: number, width: number) => {
  const time = [0, timelineMs]
  const px = [0, width]
  return {
    timeToPx: scaleLinear().domain(time).range(px),
    pxToTime: scaleLinear().domain(px).range(time),
  }
}

export const getNextPoint = (
  fromScale: number,
  toScale: number,
  point: number,
  delta: number
) => {
  const scale = scaleLinear().domain([0, fromScale]).range([0, toScale])
  const projection = scale(point)
  const nextPoint = pointProjection(toScale, fromScale, projection + delta)
  return nextPoint
}

export const reShake = (
  data: TimelineItem[],
  totalTime: number
): TimelineItem[] => {
  const result = [...data]
  const first = result[0]
  const last = result[result.length - 1]

  if (first.startPoint < 0) {
    result[0] = {
      ...first,
      startPoint: 0,
      endPoint: first.endPoint + Math.abs(first.startPoint),
    }
  }

  if (last.endPoint > totalTime) {
    const delta = totalTime - last.endPoint
    result[0] = {
      ...last,
      startPoint: last.startPoint - delta,
      endPoint: last.endPoint - delta,
    }
  }

  const shift = (item: TimelineItem, i: number, arr: TimelineItem[]) => {
    const prevEndPoint = arr[i - 1] ? arr[i - 1].endPoint : 0
    const nextStartPoint = arr[i + 1] ? arr[i + 1].startPoint : totalTime
    let startPoint = item.startPoint
    let endPoint = item.endPoint

    if (startPoint < prevEndPoint) {
      const delta = prevEndPoint - startPoint
      const isEnoughSpace = nextStartPoint && nextStartPoint - endPoint >= delta
      startPoint = isEnoughSpace ? startPoint + delta : startPoint
      endPoint = isEnoughSpace ? endPoint + delta : endPoint
    }

    if (endPoint > nextStartPoint) {
      const delta = endPoint - nextStartPoint
      const isEnoughSpace = prevEndPoint && prevEndPoint - startPoint <= delta
      startPoint = isEnoughSpace ? startPoint - delta : startPoint
      endPoint = isEnoughSpace ? endPoint - delta : endPoint
    }

    return {
      ...item,
      startPoint,
      endPoint,
    }
  }

  for (let i = 0; i < result.length; i++) {
    result[i] = shift(result[i], i, result)
  }

  for (let i = result.length - 1; i >= 0; i--) {
    result[i] = shift(result[i], i, result)
  }

  return result
}

export const getClipsDuration = (data: TimelineItem[]) =>
  data.reduce((time, item) => time + (item.endPoint - item.startPoint), 0)

export const getFreeFloat = (
  data: TimelineItem[],
  totalTime: number
): number => {
  return totalTime - getClipsDuration(data)
}

export const normalizeTime = (data: TimelineItem[]): TimelineItem[] => {
  return data.map((item) => {
    return {
      ...item,
      startPoint: Math.round(item.startPoint),
      endPoint: Math.round(item.endPoint),
    }
  })
}

export const normalizeData = (data: TimelineItem[]): TimelineItem[] => {
  const sortedData = [...data].sort((a, b) => a.startPoint - b.startPoint)
  return normalizeTime(sortedData)
}
