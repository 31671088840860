import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

const TVISearchIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M 19.936 18.431 L 15.8 14.294 C 16.578 13.224 17.038 11.908 17.038 10.487 C 17.038 6.91 14.128 4 10.55 4 C 6.974 4 4.064 6.91 4.064 10.487 C 4.064 14.064 6.974 16.973 10.55 16.973 C 11.906 16.973 13.166 16.555 14.208 15.841 L 18.367 20 L 19.936 18.431 Z M 5.966 10.487 C 5.966 7.959 8.023 5.902 10.551 5.902 C 13.079 5.902 15.136 7.959 15.136 10.487 C 15.136 13.015 13.079 15.071 10.551 15.071 C 8.022 15.071 5.966 13.015 5.966 10.487 Z" />
    </SvgIcon>
  )
}

export default TVISearchIcon
