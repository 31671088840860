import { DragStartEvent } from '@dnd-kit/core'
import { DragEndEvent } from '@dnd-kit/core/dist/types'
import { useState } from 'react'

export type DropEvent = {
  active: {
    id: string | number
    data: any
  }
  over: {
    id: string | number
    data: any
    zone: 'before' | 'after' | 'hit'
  }
}

export type UseFilmstripDnD = {
  onDrop?(e: DropEvent): void
}

export const useFilmstripDnD = ({ onDrop }: UseFilmstripDnD) => {
  const [dragItem, setDragItem] = useState(null)
  const handleDragStart = ({ active }: DragStartEvent) => {
    setDragItem(active.data.current?.content)
  }
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setDragItem(null)
    if (!onDrop || !over) return
    onDrop({
      active: {
        id: active.data.current!.id,
        data: active.data.current!.data,
      },
      over: {
        id: over.data.current!.id,
        zone: over.data.current!.zone,
        data: over.data.current!.data,
      },
    })
  }

  return {
    dragItem,
    handleDragStart,
    handleDragEnd,
  }
}
