import { useDroppable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { Box } from '@mui/material'
import { CSSProperties, FC } from 'react'
import { AudioTimeLineItemContainer } from './AudioTimelineItem.styled'
import useTransform from './hooks/ useTransform'

export type AudioTimelineItemProps = {
  id: string
  startPoint: number
  endPoint: number
  height: number
  className?: string
  style?: CSSProperties
  isActive?: boolean
  disabled?: boolean
  onTransform?(data: { id: string; delta: number }): void
}

const AudioTimelineItem: FC<AudioTimelineItemProps> = ({
  id,
  startPoint,
  endPoint,
  children,
  height,
  isActive = false,
  disabled = false,
  className = 'audio-timeline-item',
  style = {},
  onTransform,
}) => {
  const { isDragging, transform, setRef, listeners, attributes } = useTransform(
    {
      id,
      disabled,
    }
  )
  const { setNodeRef: setLeftDroppableRef } = useDroppable({
    id: `${id}-before`,
    disabled: isDragging,
    data: {
      id,
      position: 'before',
    },
  })
  const { setNodeRef: setRightDroppableRef } = useDroppable({
    id: `${id}-after`,
    disabled: isDragging,
    data: {
      id,
      position: 'after',
    },
  })

  if (!disabled && typeof transform?.x === 'number' && onTransform) {
    onTransform({ id, delta: transform.x })
  }

  const itemStyle: CSSProperties = {
    transform: transform
      ? CSS.Transform.toString({
          x: disabled ? 0 : transform.x,
          y: 0,
          scaleX: 1,
          scaleY: 1,
        })
      : undefined,
    height,
    width: endPoint - startPoint,
    display: 'inline-block',
    position: 'absolute',
    transition: 'translate',
    left: startPoint,
    zIndex: isActive ? 1 : undefined,
    ...style,
  }

  return (
    <AudioTimeLineItemContainer
      ref={setRef}
      className={className}
      style={itemStyle}
      {...listeners}
      {...attributes}
      aria-label="draggable-area"
    >
      <Box
        ref={setLeftDroppableRef}
        sx={{
          width: 0,
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      {children}
      <Box
        ref={setRightDroppableRef}
        sx={{
          width: 0,
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      />
    </AudioTimeLineItemContainer>
  )
}

export default AudioTimelineItem
